import React from 'react'

import { getClassTitle, getModuleDescriptionByClassSectionID } from '../DataAndAPIs/ClassSections'
import { CommandLine, internalLink } from '../DataAndAPIs/Links'
import { scrumIntro, learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing, estimated } from './AL00000Sprint00'

import { HelloWorldWebPart1Link } from '../Activities/HelloWorld'
import { introductionsWebsiteLink } from '../Activities/IntroductionsWebsite'
import { toolsOfTheTradeLink } from '../Activities/ToolsOfTheTrade'
import { programmingTogether } from '../DataAndAPIs/Links'

export const al20000Sprint01 = (classSectionID, sprint) => {
	return ( <div>
		<h4>{getClassTitle()}</h4>
		<h5>Sprint {sprint}: {getModuleDescriptionByClassSectionID(classSectionID, sprint-1)}</h5>

		<p>Welcome to Introduction to Computer Science! In this course we will be exploring the field of computer 
		science. The course will cover software engineering, computer architecture, data organization, 
		networking, and security. We will also explore algorithms, abstractions, and computational 
		problem-solving.</p> 

		{scrumIntro(classSectionID, sprint)}

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Participate in a blended classroom environment and as part of a scrum team</li>
			<li>Utilize HTML to create a website</li>
			<li>Utilize important developer tools like GitHub to create and update software applications</li>
			<li>Effectively utilize files, file systems, and zip files</li>
			<li>Use both graphical and command line tools to manage files and file systems</li>
		</ul>

		{list20000Sprint01(sprint)}
		{closing(sprint)}
	</div> )
}

export const list20000Sprint01 = (sprint) => {
	const daleChapter11Lecture = () => { return internalLink('chapter 11 lecture', '/activity-dale/11') }
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, HelloWorldWebPart1Link())}
			<li>Get to know your scrum team by setting up a team Discord server</li>
			<li>Complete {HelloWorldWebPart1Link()}</li>
			{initialPost(sprint)}

			<li>Read “Computer Science Illuminated” chapter 11 File Systems and Directories and review the associated {daleChapter11Lecture()}</li>
			<li>Review {CommandLine()}</li>
			<li>Complete {toolsOfTheTradeLink()}{estimated('2 hours')}</li>
			<li>Review {programmingTogether()} video tutorials</li>
			{standardActivitiesClosing(sprint, introductionsWebsiteLink(), '3 hours')}
		</ol>
	</div> )
}

// Certified for FA24 on August 9, 2024
// Todo: Consider changing link directly to sprint 1 instead of having it point to sprint overview page.
// Todo: Revamp sprint 1 so that Introductions, Getting to Know Each Other, and Hello World are all more clear.  