import { list36000Sprint02 } from '../ActivityLists/AL36000Sprint02'
import { list36000Sprint03 } from '../ActivityLists/AL36000Sprint03'

import { xyz_n_1of6_lists, xyz_n_1of6, checklistAnnouncementsPreworkAndAgenda, completeDeck, tReviewDemoSchedule } from './SL00000Sprint00'
import { xyz_3_1of6PlanningExpectations } from './SL20000Sprint03'
import { basicSlide, orderedListSlide, submissionPercentage, tPrework, sprintDemosIntro, sprintDemos, demoAssignment } from './SLSprint00'

import { midTermExam } from './SL20000Sprint03'

import { xyz_n_2of6, xyz_n_4of6, xyz_n_5of6 } from './SL00000Sprint00'
import { breakoutStandard } from './SLSprint00'
import { tQuiz } from './SLSprint00'
import { xyz_n_6of6 } from './SL00000Sprint00'
import { xyz_n_1of6_prework_list } from './SL00000Sprint00'

// Sprint 3 Constants
const sprint = 3
const activityList = () => { return list36000Sprint03(sprint) }
const activityListPrevious = () => { return list36000Sprint02(sprint-1) }

// Sprint 3 session 1 of 6: Monday
export const ptt_3_1of6_PAaA = xyz_n_1of6_lists(sprint)
export const ptt_3_1of6 = () => { return xyz_n_1of6(sprint, ptt_3_1of6_PAaA.prework, activityListPrevious, ptt_3_1of6_PAaA.agenda, 
	ptt_3_2of6_PAaA.prework, activityList, xyz_3_1of6PlanningExpectations ) }

// Session 2 of 6: Wednesday
const ptt_3_2of6_PAaA = {
	'prework': [
		'Complete through activity 4 prior to next class', '',
		`Be prepared for Discussion ${sprint} and Lab Wednesday`,
		'Demos and retrospectives will be Friday' ],
	'announcements':[
		'Reducing procrastination is on the agenda' ],
	'agenda':[
		`Sprint ${sprint} Planning Questions & Answers`,
		`Discussion Board ${sprint} as a scrum team`,
		'Confirm scrum team Demos for Next Class',
		'Prework for Next Class',
		'Lab' ]
}
export const ptt_3_2of6 = () => { return xyz_n_2of6(sprint, ptt_3_2of6_PAaA, ptt_3_3of6_PAaA.prework, activityList)}

// Session 3 of 6: Friday
const ptt_3_3of6_PAaA = {
	'prework': [
		'Complete through activity 7 prior to next class', '',
		'Be prepared to discuss “The Information Layer”' ],
	'announcements':[ 
		'Any announcements?' ],
	'agenda':[
		`Sprint ${sprint-1} Demos`,
		`Sprint ${sprint-1} Retrospective`,
		`Breakout for Sprint ${sprint-1} Retrospective`,
		'Prework for Next Class' ]
}
export const ptt_3_3of6 = () => { 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_3_3of6_PAaA, sprint, activityList)
	const metrics = () => {
		return basicSlide(`Sprint ${sprint-1} Metrics`, [
			'What is Bob Parsons Rule #9?', '',
			`Let’s take a minute and review our Sprint ${sprint-1} Submission Percentage class metric.` ])
	}
	const metricsSubmissionPercentage = () => {
		return submissionPercentage([
			{ name: 'Discussion', due:8, submitted:8 },
			{ name: 'Quiz', due:8, submitted:8 },
			{ name: 'Lab', due:8, submitted:8 },
			{ name: 'Reflection', due: 8, submitted: 8 }])
	}
	const retrospective = () => {
		return orderedListSlide('Class Retrospective',
			'Feedback from Assignments & Reflections', [
			'Wow! 100% submission percentage again in sprint 2',
			'Reward for perfect 100% submission percentage... how would we like to celebrate?',
			'Lab 2 assignments are not graded and posted... sorry', 
			'Thank you for your reflection comments',
			'Wonderful comments about teammates and working with them... you are appreciated' ])
	}
	const retrospectiveBreakout = () => {
		return orderedListSlide('Breakout Session for Team Retrospective', 
			'As a scrum team consider:', [
			`How does your team feel about sprint ${sprint-1} now that it is over`,
			`What could be done to make sprint ${sprint-1} or the class overall better or more manageable`,
			'What improvements should we make as a class, team, or individual going forward' ])
	}
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_3_4of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ tReviewDemoSchedule, sprintDemosIntro, sprintDemos, demoAssignment, metrics, 
		metricsSubmissionPercentage, retrospective, retrospectiveBreakout, preworkNext ])
}

const ptt_3_4of6_PAaA = {
	'prework': [
		'Complete through activity 10 prior to next class', '',
		`Be prepared for Object-Oriented Programming vs. Functional Programming breakout` ],
	'announcements':[ 
		'Any announcements or questions?' ],
	'agenda':[ 
		'Sprint Progress Polling',
		`Breakout`,
		'Prework for Next Class',
		'Lab' ]
}

const diceRollerPlusConfigurationManagementName = 'Dice Roller Plus Configuration Management'
const breakoutdiceRollerPlusConfigurationManagement = () => { 
	return breakoutStandard(`Breakout: ${diceRollerPlusConfigurationManagementName}`, 
		`In this breakout session on ${diceRollerPlusConfigurationManagementName} your team will:`, [
		'Review the Dice Roller Plus Configuration Management for the static website',
		'Discuss what is remaining for the test and production static websites',
		'Discuss the Dice Roller Plus Configuration Management for the Node.js website',
		'Implement the Dice Roller Plus for the test Node.js website',
		'Implement the Dice Roller Plus for the production Node.js website' ])
}
export const ptt_3_4of6 = () => { return xyz_n_4of6(sprint, ptt_3_4of6_PAaA, ptt_3_5of6_PAaA.prework, activityList, breakoutdiceRollerPlusConfigurationManagement) }

const ptt_3_5of6_PAaA = {
	'prework': [
		'Complete through activity 10 prior to next class', '',
		'Be prepared for Lab and Programming Together',
		`Be prepared for Quiz ${sprint}` ],
	'announcements':[ 
		`${midTermExam} will be our midterm Exam your in person attendance is required`,
		`All sprint ${sprint} assignments due Sunday` ],
	'agenda':[
		'Sprint Progress Polling',
		'Lab and Programming Together',
		'Prework for Next Class',
		`Quiz ${sprint}` ]
}
export const ptt_3_5of6 = () => { return xyz_n_5of6(sprint, ptt_3_5of6_PAaA, ptt_3_6of6_PAaA.prework, activityList) }

// Session 6 of 6: Friday
const ptt_3_6of6_PAaA = {
	'prework': [
		'Complete through activity 11 prior to next class', '',
		'Be prepared for Lab and Programming Together'],
	'announcements':[ 
		`${midTermExam} will be our midterm Exam your in person attendance is required`,
		`All sprint ${sprint} assignments due Sunday`,
		'Since we did not have time for Quiz 3 on Wednesday will leave time for it today' ],
	'agenda':[
		'Lab and Programming Together',
		'Prework for Next Class',
		`Quiz ${sprint}` ]
}
export const ptt_3_6of6 = () => { 
	const quiz = () => { return tQuiz(sprint) }

	let slides = xyz_n_6of6(sprint, ptt_3_6of6_PAaA, xyz_n_1of6_prework_list(sprint+1), activityList) 
	slides.splice(slides.length-1, 0, quiz)
	return slides
}
