import React from 'react'

import { makeNavigationLink } from './ActivityLink'
import { diceRollerAPIWithNodeLink } from './WebAssignmentPortfolioWithNodeJS'
import { oreillyPlaylistPTT, docker } from '../DataAndAPIs/Links'

const renderGoWebServer = () => {
	return ( <div><br />
		<h3>{goWebServer.name}</h3>
		<h5>Summary</h5>
		<p>Utilize the Go “/net/http” and “/embed” Frameworks to create a Go web server</p>
		<h5>Prerequisites</h5>
		<p>Go</p>
		<h5>Instruction & Requirements</h5>
		<p>Utilize the Go “/net/http” and “embed” Frameworks to create a Go web server. The server should implement 
			multiple APIs, including hello-world, hello-world-json, hello-world-json-static, and finally, implement 
			a JSON return that is an embedded JSON file (using the “embed” framework).</p>
		<em>Requirement 1</em>
		<p>Create an application that:</p>
		<ol type='n'>
			<li>Is implemented in multiple Go (.go) files with a primary Go file named “gws.go” (short for “Go Web Server”)</li>
			<li>Successfully compiles from the command line by typing “go run gws.go</li>
			<li>Store all code to process each standard in a separate Go file</li>
			<li>Includes user instructions that describe the application when “gws help” is executed</li>
		</ol>

		<em>Requirement 2</em>
		<p>Enhance your application so that it also:</p>
		<ol type='n'>
			<li>Utilizes the “/net/http” Go Framework to process HTTP request</li>
			<li>Implements hello-world by responding in plane text with “Hello World – GWS”</li>
			<li>Implements hello-world-html by responding in HTML with “Hello World – GWS” including the “header 1” font and 
				an mDash between “Hello World” and “GWS”</li>
			<li>Implements hello-world-json by responding in JSON “&#123;“message”:”Hello World - GWS”&#125;</li>
		</ol>

		<em>Requirement 3</em>
		<p>Be sure that the application also:</p>
		<ol type='n'>
			<li>Implements the embedding of JSON file GWS using the “embed” Go library</li>
			<li>Embed a “Syllabus” JSON file in each in a separate Go file</li>
			<li>Implement the “syllabus” API first implementing Read (of CRUD) in response to the “GET” HTTP request</li>
			<li>Implement a “stubbed” Delete request that responds back with text “deleted – stubbed”</li>
		</ol>

		<em>Requirement 4</em>
		<p>Enhance your application so that it also:</p>
		<ol type='n'>
			<li>Implements Create and Update both of which respond with “create-stubbed” and “update-stubbed” respectively</li>
			<li>Implements the “help” API returning API help that includes links to each of the other APIs</li>
		</ol>
	</div> )
}

export const goWebServer = { 'name':'Go Web Server', 'route':'go-web-server', 'render': renderGoWebServer }
export const goWebServerLink = () => { return makeNavigationLink(goWebServer) }


const renderGoWebServerWithDocker = () => {
	return ( <div><br />
		<h3>{goWebServerWithDocker.name}</h3>
		<h5>Summary</h5>
		<p>Utilize {docker()} to package {goWebServerLink()} in a {docker()} container while enhanceing the containerized 
			application so that it supports Server Dice Roller (/d6) REST API. Finally, host {goWebServerWithDocker.name}
			in Microsoft Azure and convert your {diceRollerAPIWithNodeLink()} client application so that utilizes the
			Azure hosted containerized instance of {goWebServerLinkWithDockerLink()}.</p>
		<h5>Prerequisites:</h5>
		<ul style={{listStyleType:'square'}}>
			<li>Go</li>
			<li>{docker()}</li>
			<li>{goWebServerLink()}</li>
			<li>{diceRollerAPIWithNodeLink()}</li>
		</ul>
		
		<h5>Instruction & Requirements</h5>
		<em>Requirement 1</em>
		<p>Review and test your previously implemented {goWebServerLink()} by:</p>
		<ol type='n'>
			<li>Reviewing the original requirements form {goWebServerLink()}</li>
			<li>Compiling, running, and testing your previosly developed {goWebServerLink()}</li>
			<li>Enhanceing {goWebServerLink()} so that it implements the “/d6” API which return and integer between 1 and 6 inclusive</li>
			<li>Test your {goWebServerLink()} with your Dice Roller client application running locally</li>
		</ol>

		<em>Requirement 2</em>
		<p>Become familiar with Docker and Docker Containers by:</p>
		<ol type='n'>
			<li>Review {docker()}</li>
			<li>Install {docker()} and create “Hello World – Docker” in a Docker Container</li>
			<li>Within {oreillyPlaylistPTT()} review “Docker: Up & Running” and “Cloud Native Go”</li>
			<li>Utilize ChatGPT and/or Google search to find and complete two tutorials on deploying web applications on azure 
				using docker containers</li>
		</ol>

		<em>Requirement 3</em>
		<p>Be sure that the application also:</p>
		<ol type='n'>
			<li>Continerizing your enhanced version of {goWebServerLink()}</li>
			<li>Host your enhanced version of {goWebServerLink()} on Azure</li>
			<li>Enhance your {diceRollerAPIWithNodeLink()} client so that it has a dropdown to select Node.js or Docker API servers</li>
			<li>Create a test site and enhance your {diceRollerAPIWithNodeLink()} client to allow the test site to be selected</li>
		</ol>

		<em>Requirement 4</em>
		<p>Research additional GraphQL relating enhancements by:</p>
		<ol type='n'>
			<li>Within {oreillyPlaylistPTT()} review “JavaScript Everywhere” and “Learning GraphQL” with a focus on GraphQL</li>
			<li>Research adding a GraphQL API in your {diceRollerAPIWithNodeLink()} client</li>
			<li>Research adding a GraphQL API in your {diceRollerAPIWithNodeLink()} Node.js server</li>
			<li>Research adding a GraphQL API in your Go containerized server</li>
		</ol>
	</div> )
}

export const goWebServerWithDocker = { 'name':'Go Web Server with Docker', 'route':'go-web-server-with-docker', 'render': renderGoWebServerWithDocker }
export const goWebServerLinkWithDockerLink = () => { return makeNavigationLink(goWebServerWithDocker) }
