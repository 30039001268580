import { xyz_n_1of6_lists, xyz_n_1of6, xyz_2_2of6, tNoClassToday, completeDeck, checklistAnnouncementsPreworkAndAgenda } from './SL00000Sprint00'

import { tPrework, submissionPercentage, orderedListSlide } from './SLSprint00'
import { tReviewDemoSchedule } from './SL00000Sprint00'

import { metricsAndBobParsonsQuote } from './SL20000Sprint04'

import { sprintDemosIntro, sprintDemos, demoAssignment, } from './SLSprint00'

import { list36000Sprint03 } from '../ActivityLists/AL36000Sprint03'
import { list36000Sprint04 } from '../ActivityLists/AL36000Sprint04'

import { xyz_4_1of6PlanningExpectations, classProjects } from './SL20000Sprint04'
//import { tUnderConstruction } from './SL00000Sprint00'
import { tExam, tExamExpectations } from './SLSprint00'
import { tLab } from './SL00000Sprint00'
import { basicSlide } from './SLSprint00'

// Sprint Constants
const sprint = 4
const activityList = () => { return list36000Sprint04(sprint) }
const activityListPrevious = () => { return list36000Sprint03(sprint-1) }

// Sprint 4 session 1 of 6: Monday
export const ptt_4_1of6_PAaA = xyz_n_1of6_lists(sprint)
export const ptt_4_1of6 = () => { 
	let slides = xyz_n_1of6(sprint, ptt_4_1of6_PAaA.prework, activityListPrevious, ptt_4_1of6_PAaA.agenda, ptt_4_2of6_PAaA.prework, activityList, xyz_4_1of6PlanningExpectations ) 
	slides.splice(4, 0, classProjects)
	return slides
}

// Session 2 of 6: Wednesday
const ptt_4_2of6_PAaA = {
	'prework': [
		'Complete through activity 4 prior to next class', '',
		`Be prepared for Discussion ${sprint} and Lab Wednesday`,
		'Demos and retrospectives will be Friday' ],
	'announcements':[
		'Reducing procrastination is on the agenda' ],
	'agenda':[
		`Sprint ${sprint} Planning Questions & Answers`,
		`Discussion Board ${sprint} as a scrum team`,
		'Confirm scrum team Demos for Next Class',
		'Prework for Next Class',
		'Lab' ]
}
export const ptt_4_2of6 = () => { return xyz_2_2of6(sprint, activityList, ptt_4_2of6_PAaA, ptt_4_4of6_PAaA) }

// Session 3 of 6: Friday
export const ptt_4_3of6 = () => { return [ tNoClassToday ] }

// Session 4 of 6: Monday
const ptt_4_4of6_PAaA = {
	'prework': [
		'Complete through activity 9 prior to next class', '',
		`Be prepared for sprint ${sprint-1} demos and retrospectives`,
		'Those scheduled to demo please be a couple of minutes early to class' ],
	'announcements':[ 
		'**Wednesday is our midterm Exam your in person attendance is required', '',
		'Registration is coming',
		'Are you interested in a Software Engineering area of concentration?... maybe the 4+1 program? ' ],
	'agenda':[
		`Sprint ${sprint-1} Demos`,
		`Sprint ${sprint-1} Retrospective`,
		`Breakout for Sprint ${sprint-1} Retrospective`,
		'Prework for Next Class' ]
}
export const ptt_4_4of6 = () => { 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_4_4of6_PAaA, sprint, activityList)
	const metricsSubmissionPercentage = () => {
		const submissionPercentageData = [
			{ name: 'Discussion', due:8, submitted:8 },
			{ name: 'Quiz', due:8, submitted:8 },
			{ name: 'Lab', due:8, submitted:8 },
			{ name: 'Reflection', due: 8, submitted: 8 }]

		return submissionPercentage(submissionPercentageData)
	}
	// Todo: Add the Scrum process pretty slides back into slide deck for Demos and Retrospectives. 
	const retrospective = () => {
		return orderedListSlide('Class Retrospective',
			'Feedback from Assignments & Reflections', [
			'Yet another sprint with 100% submission rate... Thank you!', 
			'Grading with the exception of Lab 3 is complete and grades are posted',
			'You should be able to see the answers to all quiz questions',
			'Multiple reflection comments about appreciating the time programming together',
			'Several reflection comments about the beauty of starting early and wonderful teammates' ])
	}
	const retrospectiveBreakout = () => {
		return orderedListSlide('Breakout Session for Team Retrospective', 
			'As a scrum team consider:', [
			`How does your team feel about sprint ${sprint-1} now that it is over`,
			`What could be done to make sprint ${sprint-1} or the class overall better or more manageable`,
			'What improvements should we make as a class, team, or individual going forward' ])
	}
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_4_5of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ tReviewDemoSchedule, sprintDemosIntro, sprintDemos, demoAssignment, metricsAndBobParsonsQuote, 
		metricsSubmissionPercentage, retrospective, retrospectiveBreakout, preworkNext ])
}

// Session 5 of 6: Wednesday (midterm Exam)
const ptt_4_5of6_PAaA = {
	'prework':[
		'**Be prepared to take you midterm exam in person on Wednesday' ],
	'announcements':[ 
		`All sprint ${sprint} assignments due Sunday, March 17th!` ],
	'agenda':[
		'Midterm Exam' ],
}

export const ptt_4_5of6 = () => { 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_4_5of6_PAaA, sprint, activityList)
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_4_6of6_PAaA.prework, sprint, activityList) }
	return completeDeck(slideDeck, [ preworkNext, tExamExpectations, tExam ])
}

const ptt_4_6of6_PAaA = {
	'prework':[
		'Complete through activity 9 prior to next class', '',
		'Be prepared for Lab & Programming together' ],
	'announcements':[ 
		`All sprint ${sprint} assignments due Sunday` ],
	'agenda':[
		'Final Activity List & Assignment Review',
		'Prework for Next Class',
		'Lab and Programming Together' ]
}
export const ptt_4_6of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_4_6of6_PAaA, sprint, activityList)
	const finalReviewActivityListAndAssignments = () => { return basicSlide(
		`Final sprint ${sprint} activity list and assignment Q&A`, [`Any sprint ${sprint} activity list or assignment questions?`]) }
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_5_1of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ finalReviewActivityListAndAssignments,preworkNext, tLab ])
}

const ptt_5_1of6_PAaA = {
	'prework':[
		`All sprint ${sprint} assignments due Sunday!`, '',
		`Be prepared for sprint ${sprint+1} planning` ],
	'announcements':[ 
		'' ],
	'agenda':[
		'' ]
}

export const ptt_4_slidedecks = [ ptt_4_1of6, ptt_4_2of6, ptt_4_3of6, ptt_4_4of6, ptt_4_5of6, ptt_4_6of6 ]

