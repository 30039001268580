import { tPrework } from './SLSprint00'

// import { list49200Sprint07 } from '../ActivityLists/AL49200Sprint07'
import { list49200Sprint08 } from '../ActivityLists/AL49200Sprint08'

import { checklistAnnouncementsPreworkAndAgenda, completeDeck } from './SL00000Sprint00'

import { finalComments } from './SL20000Sprint08'
import { orderedListSlide } from './SLSprint00'

// Constants
const sprint = 8
// const activityListPrevious = () => { return list49200Sprint07(sprint-1) }
const activityList = () => { return list49200Sprint08(sprint) }

// Monday
const ssc_8_1of4_PAaA = {
	'announcements':[ 
		'Class Product Presentations',
		'Please consider taking time to complete your course evaluation' ],
	'prework':[
		'Be prepared for your class product presentations' ],
	'agenda':[
		'Product Overview',
		'Individual Class Product Presentations',
		'Thank you!' ] 
}
export const ssc_8_1of4 = () => { 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ssc_8_1of4_PAaA, sprint, activityList)
	const projectPresentations = () => {
		return orderedListSlide(
			'Class Product Presentation (15-18 minutes)', 
			`Your class product presentation should include: `, [
			'A self introduction of each team member as they present',
			'Overview of current customers and how they utilize the product',
			'Demonstration of a feature you are the most proud of delivering',
			'Discussion of related source code' ])
	}

	const nextSteps = () => { return tPrework('Next Steps', [ 'Everything is due Wednesday!' ], sprint, activityList) }
	return completeDeck(slideDeck, [ projectPresentations, nextSteps, finalComments ]) 
}
