import React from 'react'

import { makeNavigationLink } from './ActivityLink'
import { externalLink } from '../DataAndAPIs/Links'
import { gettingToKnowEachOtherLink } from './WebGettingToKnowEachOther'
import { azureWebsiteLink } from './AzureWebsite'

import ReactPlayer from 'react-player'
import { ExampleCode } from '../DataAndAPIs/Links'

const renderWebDiceRollerPlus = () => {
	const Yahtzee = () => externalLink('Yahtzee', 'https://en.wikipedia.org/wiki/Yahtzee')
	return ( <div><br />
		<h4>{webDiceRollerPlus.name}</h4>
		<h5>Summary: Create a website that simulates dice rolling and host it as a static website and a Node.js website.</h5>
		<h5>Prerequisites: </h5>
		<ul style={{listStyleType:'square'}}>
			<li>{gettingToKnowEachOtherLink()}</li>
			<li>{azureWebsiteLink()}</li>
		</ul>
					
		<p><em>Instructions:</em> Many games require dice to be rolled to generate random numbers. This week you will create an 
		application that rolls dice for a game of your choice. If you cannot come up with a game, you are welcome to create a 
		dice roller that rolls dice for the Yahtzee game {Yahtzee()} games which requires each player to roll five 6-sided dice.</p>

		<p>Note that this is intended to be a fairly simple project that only provides the dices rolling aspect of the game. 
		The project does not require you to implement additional game play.</p>

		<em>Requirement 1</em>
		<p>Create website that:</p>
		<ol>
			<li>Utilizes separate HTML, CSS, and JavaScript files and is hosted in Microsoft Azure</li>
			<li>Has a simple yet an aesthetically pleasing user interface</li>
			<li>Includes instructions that succinctly describes the application and how to roll dice for the game</li>
			<li>Utilized random numbers to simulate rolling dice</li>
		</ol>

		<em>Requirement 2</em>
		<p>The website should also:</p>
		<ol>
			<li>Automatically roll the dice the first time when the page is first loaded (Hint: onload)</li>
			<li>Automatically focus the "roll" button so that pressing the enter key rolls the dice again (Hint: autofocus)</li>
			<li>Have meaningful field headers, all numbers right justified, and all random fields read only</li>
			<li>Have all numbers right justified and all random number fields implemented as read only</li>
			<li>Implement a visible environment identifier (i.e. Node Test, Node Production, etc.) and a version number (i.e. 2.04)</li>
		</ol>

		<em>Requirement 3</em>
		<p>Enhance the website by:</p>
		<ol>
			<li>Hosting the “Main” GitHub branch on a Azure Static website named web-roller-test</li>
			<li>Creating a “Production” GitHub branch and hosting it on an Azure Static website named web-roller-production</li>
			<li>Test changing on your local computer, pushing them to Test, and then merging them to Produciton</li>
			<li>Add a “secret” to the site configuration to add a “Test Azure Static“ tag in the upper right corner to the test site</li>
		</ol>

		<em>Requirement 4</em>
		<p>Recreating Requirement 3 as a Node.js website by:</p>
		<ol>
			<li>Implementing a new version of dice roller and host it with Node.js on a Azure Node.js website</li>
			<li>Hosting the “Main” GitHub branch on a Node.js Website</li>
			<li>Creating a “Production” GitHub branch and hosting it on an Node.js Azure website named web-roller-production</li>
			<li>Test changing on your local computer, pushing them to Test, and then merging them to Produciton</li>
			<li>Add a “secret” to the site configuration to add a “Test Node.js“ tag in the upper right corner to the test site</li>
		</ol>

		<p>Be sure to complete the following tutorial on deploying Node.js on Azure. The code can be found in the {ExampleCode()} repository in the “web” section.</p>
		<ReactPlayer url='https://lewiseducation.blob.core.windows.net/configuration/deploying-node-js-on-microsoft-azure.mp4' controls={true} width={1024} height={576} />
	</div> )
}

export const webDiceRollerPlus = { 'name':'Web Dice Roller Plus', 'route':'web-dice-roller-plus', 'render':renderWebDiceRollerPlus }
export const webDiceRollerPlusLink = () => { return makeNavigationLink(webDiceRollerPlus) }
