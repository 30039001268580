
import { tUnderConstruction, xyz_n_4of6_PAaA, xyz_n_4of6 } from './SL00000Sprint00'
import { submissionPercentage } from './SLSprint00'
import { orderedListSlide, tDiscussionBreakout } from './SLSprint00'
import { breakoutStandard } from './SLSprint00'

// Todo: Add activityListPrevious when updating ptt_6_1of6.
// import { list36000Sprint05 } from '../ActivityLists/AL36000Sprint05'
import { list36000Sprint06 } from '../ActivityLists/AL36000Sprint06'
import { xyz_n_2of6 } from './SL00000Sprint00'
import { xyz_n_3of6 } from './SL00000Sprint00'
import { xyz_n_6of6_PAaA, xyz_n_5of6 } from './SL00000Sprint00'
import { xyz_n_6of6 } from './SL00000Sprint00'
import { insertInto } from './SLSprintPlanning'

// Sprint Constants
const sprint = 6
const activityList = () => { return list36000Sprint06(sprint) }
// Todo: Add activityListPrevious when updating ptt_6_1of6.
// const activityListPrevious = () => { return list36000Sprint05(sprint-1) }

const progress = [ 0, 4, 7, 7, 8, 9 ]

const registration = 'Spring 2024 registration begins November 11... are you ready?'

const ptt_6_1of6 = () => { return [ tUnderConstruction ] }

// Session 2 of 6: Wednesday
const ptt_6_2of6_PAaA = { 
	'prework':[
		`Complete through activity ${progress[2-1]} prior to next class`, '',
		`Be prepared Discussion Board ${sprint}`,
		'Be prepared for Lab & Programming Together' ],
	'announcements':[ 
		`${registration}` ],
	'agenda':[
		`Discussion Board ${sprint} as a scrum team`,
		'Review Demo Schedule for Next Class',
		'Lab & Programming Together',
		'Prework for Next Class', ]
}
const ptt_6_2of6 = () => { return xyz_n_2of6(sprint, ptt_6_2of6_PAaA, ptt_6_3of6_PAaA.prework, activityList) }

// Session 3 of 6: Friday
const ptt_6_3of6_PAaA = {
	'prework':[
		`Complete through activity ${progress[3-1]} prior to next class`, '',
		`Be prepared sprint ${sprint-1} Demos and Retrospectives`,
		'Be prepared for Discussion 6 as a team' ],
	'announcements':[ 
		`${registration}` ],
	'agenda':[
		`Sprint ${sprint-1} Demos`,
		`Sprint ${sprint-1} Retrospective`,
		`Skipping or Breakout for Sprint ${sprint-1} Retrospective`,
		`Discussion 6`,
		'Prework for Next Class' ]
}
const ptt_6_3of6 = () => {
	const metricsSubmissionPercentage = () => {
		return submissionPercentage([
			{ name: 'Discussion', due:8, submitted:8 },
			{ name: 'Quiz', due:8, submitted:8 },
			{ name: 'Lab', due:8, submitted:7 },
			{ name: 'Reflection', due: 8, submitted: 8 }])
	}
	const retrospective = () => {
			return orderedListSlide('Class Retrospective',
			'Feedback from Assignments & Reflections', [
				'Solid submission percentage',
				'All assignments are graded and posted', 
				'Sprint 5 was challenging', 
				'Very nice work!' ])
	}
	const discussionBreakout = () => { return tDiscussionBreakout(sprint) } 
	const slideDeck =  xyz_n_3of6(sprint, ptt_6_3of6_PAaA, ptt_6_4of6_PAaA.prework, activityList, metricsSubmissionPercentage, retrospective)
	
	return insertInto(slideDeck, [discussionBreakout], 14)
}

// Session 4 of 6: Monday
const iaCbreakoutName = 'Infrastructure as Code (IaC)'
const iaCbreakout = () => { 
	return breakoutStandard( 
		`${iaCbreakoutName}`, 
		'In this breakout will discuss Infrastructure as Code (IaC):', [
		'Declaritive vs Imperative',
		'Compare Terraform, Ansible, CloudFormation, and ARM Templates',
		'Does Google Cloud Platform have IaC?',
		'Describe the Imparative approach we will use in “MERNa Infrastructure as Code (IaC)”',
		'Identify each of the Azure Templates to utilize in “MERNa Infrastructure as Code (IaC)”',
		'What is GitHub’s IaC environment?',])
}
const ptt_6_4of6_PAaA = xyz_n_4of6_PAaA(sprint, `Complete through activity ${progress[4-1]}`, iaCbreakoutName )
const ptt_6_4of6 = () => { return xyz_n_4of6(sprint, ptt_6_4of6_PAaA, ptt_6_5of6_PAaA.prework, activityList, iaCbreakout) }

// Session 5 of 6: Wednesday
const ptt_6_5of6_PAaA = {
	'prework':[
		`Complete through activity ${progress[5-1]} prior to next class`, '',
		'Be prepared for Lab' ],
	'announcements':[ 
		'Registration, registration, and more registration' ],
	'agenda':[
		'Lab',
		`Quiz ${sprint}` ]
}
const ptt_6_5of6 = () => { return xyz_n_5of6(sprint, ptt_6_5of6_PAaA, ptt_6_6of6_PAaA.prework, activityList) }


const ptt_6_6of6_PAaA = xyz_n_6of6_PAaA(`Complete through activity ${progress[6-1]} prior to next class`, sprint)

const ptt_xplus1_1of6_Prework = [
	`All sprint ${sprint} assignments due Sunday!`, '',
	`Be prepared for sprint ${sprint+1} planning` ]
const ptt_6_6of6 = () => { return xyz_n_6of6(sprint, ptt_6_6of6_PAaA, ptt_xplus1_1of6_Prework, activityList) }

export const ptt_6_slidedeck = [ ptt_6_1of6, ptt_6_2of6, ptt_6_3of6, ptt_6_4of6, ptt_6_5of6, ptt_6_6of6 ]