import { _CPSC_20000, _CPSC_24500, _CPSC_24700, _CPSC_36000, _CPSC_44000, _CPSC_44500, _CPSC_49200 } from "./Classes"
import { _CALENDAR_FALL_2024_08_26_16 } from "./Calendars"
import { _INSTRUCTOR_ERIC_POGUE } from "./Instructors"
import { students_SP24_CPSC_49200_002 } from "./Students"

export const getClassTitleByClassSectionID = (classSectionID) => {
	return getClassSectionByID(classSectionID).course.name
}

export const getClassTitle = () => {
	return getCurrentClassSection().course.name
}

export const getClassNumber = () => {
	return getCurrentClassSection().course.number.toString()
}

export const getClassCalendarByClassSectionID = (classSectionID) => { 
	return getClassSectionByID(classSectionID).calendar.sprintDates
}

export const getClassCalendar = () => { 
	return getCurrentClassSection().calendar.sprintDates
}

export const getFinalExamDateAndTime = () => {
	return getCurrentClassSection().finalExam
}

export const getIsScheduleT = () => {
	return (getCurrentClassSection().meetingDays === _T)
}

export const getIsScheduleTTr = () => {
	return (getCurrentClassSection().meetingDay === _TTR)
}

export const getIsScheduleOnline = () => {
	return (getCurrentClassSection().meetingDay === _ASYNCHRONOUS)
}

export const getClassSectionID = () => {
	return getCurrentClassSection().id
}

export const getModuleDescriptionByClassSectionID = (classSectionID, moduleIndex) => {
	return getClassSectionByID(classSectionID).course.modules[moduleIndex]
}

export const getModuleDescription = (moduleIndex) => {
	return getCurrentClassSection().course.modules[moduleIndex]
}

export const getModuleDescriptionsByClassSectionID = (classSectionID) => {
	return getClassSectionByID(classSectionID).course.modules
}

export const getModuleDescriptions = () => {
	return getCurrentClassSection().course.modules
}

const moduleAssignments = [
	'Discussion, Quiz, Lab, and Reflection',
	'Discussion, Quiz, Lab, and Reflection',
	'Discussion, Quiz, Lab, and Reflection',
	'Discussion, Midterm Exam, Lab, and Reflection',
	'Discussion, Quiz, Lab, and Reflection',
	'Discussion, Quiz, Lab, and Reflection',
	'Discussion, Final Exam, Lab, Reflection, and Lab Demo',
	'Lab, Reflection'
]
export const getModuleAssignment = (module) => {
	return moduleAssignments[module]
}

// To promote encapsulation use 'getAllClassSections()' and 'getClassSectionByID()' sparingly. 
export const getAllClassSections = () => {
	return _CLASS_SECTION_LIST
}

export const getClassSection = () => {
	return getCurrentClassSection().section
}

export const getClassSectionByID = (classSectionID) => {
	for (let i = 0; i < _CLASS_SECTION_LIST.length; i++) {
		if (_CLASS_SECTION_LIST[i].id === classSectionID)
			return _CLASS_SECTION_LIST[i]
	}

	console.log(`Error: Class Section ID “${classSectionID}” not found.`)
	return null
}

const getClassSectionIDfromURL = () => {
	// Depreciated: Use getCurrentClassSectionID() instead.
	return sessionStorage.getItem('classSectionID')
}

const _MWF = 'MWF'
const _T = 'T'
const _TTR = 'TTh'
const _ASYNCHRONOUS = 'Asynchronous' 

const _DEFAULT_SEMESTER_PREFIX = 'fa24'
const _DEFAULT_CALENDAR = _CALENDAR_FALL_2024_08_26_16

const getCurrentClassSection = () => {
	return getClassSectionByID(getClassSectionIDfromURL())
}

// Confirmed on August 8, 2024.
const _FA24_CPSC_20000_002 = {
	'id':'fa24-cpsc-20000-002', 
	'section':'002',

	'crn':'80006',
	'meetingDays':_MWF,
	'meetingTimes':'10-10:50 pm',
	'finalExam':'Monday, December 9 from 10:30 AM to 12:30 PM CT',
	'meetingLocation':'Arts and Sciences (AS) 104A with Friday remote via Zoom',

	'course':_CPSC_20000,
	'instructor':_INSTRUCTOR_ERIC_POGUE,
	'calendar':_CALENDAR_FALL_2024_08_26_16
}

const _FA24_CPSC_36000_001 = {
	'id':'fa24-cpsc-36000-001',
	'section':'001',
	'crn':'80045',
	'meetingDays':_MWF,
	'meetingTimes':'11-11:50 am',
	'finalExam':'Friday, December 13 from 10:30 to 12:30 pm CT',
	'meetingLocation':'Arts and Sciences (AS) 104A with Friday remote via Zoom',

	'course':_CPSC_36000,
	'instructor':_INSTRUCTOR_ERIC_POGUE,
	'calendar':_CALENDAR_FALL_2024_08_26_16
}

const _FA24_CPSC_44000_001 = {
	'id':'fa24-cpsc-44000-001',
	'section':'001',
	'crn':'80052',
	'meetingDays':_MWF,
	'meetingTimes':'1-1:50 pm',
	'finalExam':'Wednesday, December 11 from 1:30 to 3:30 pm CT',
	'meetingLocation':'Arts and Sciences (AS) 104A with Friday remote via Zoom',

	'course':_CPSC_44000,
	'instructor':_INSTRUCTOR_ERIC_POGUE,
	'calendar':_CALENDAR_FALL_2024_08_26_16
}

const _FA24_CPSC_49200_001 = {
	'id':'fa24-cpsc-49200-001',
	'section':'001',
	'crn':'80055',
	'meetingDays':_T,
	'meetingTimes':'11 am-12:15 pm',
	'finalExam':'Tuesday, December 10 from 10:30 am to 12:30 pm CT',
	'meetingLocation':'Arts and Sciences (AS) 140S',

	'course':_CPSC_49200,
	'instructor':_INSTRUCTOR_ERIC_POGUE,
	'calendar':_CALENDAR_FALL_2024_08_26_16,
	'students':students_SP24_CPSC_49200_002
}

const _FA24_CPSC_49200_004 = {
	'id':'fa24-cpsc-49200-004',
	'section':'004',
	'crn':'82391',
	'meetingDays':_MWF,
	'meetingTimes':'2-2:50 pm',
	'finalExam':'Monday, December 9 from 4:00 pm to 6:00 pm CT',
	'meetingLocation':'Arts and Sciences (AS) 140S',

	'course':_CPSC_49200,
	'instructor':_INSTRUCTOR_ERIC_POGUE,
	'calendar':_CALENDAR_FALL_2024_08_26_16,
	'students':students_SP24_CPSC_49200_002
}

const _XXXX_CPSC_24500 = {
	'id':`${_DEFAULT_SEMESTER_PREFIX}-${_CPSC_24500.id}`,

	'course':_CPSC_24500,
	'instructor':_INSTRUCTOR_ERIC_POGUE,
	'calendar':_DEFAULT_CALENDAR
}

const _XXXX_CPSC_24700 = {
	'id':`${_DEFAULT_SEMESTER_PREFIX}-${_CPSC_24700.id}`,

	'course':_CPSC_24700,
	'instructor':_INSTRUCTOR_ERIC_POGUE,
	'calendar':_DEFAULT_CALENDAR
}

const _XXXX_CPSC_36000 = {
	'id':`${_DEFAULT_SEMESTER_PREFIX}-${_CPSC_36000.id}`,

	'course':_CPSC_36000,
	'instructor':_INSTRUCTOR_ERIC_POGUE,
	'calendar':_DEFAULT_CALENDAR
}

const _XXXX_CPSC_44500 = {
	'id':`${_DEFAULT_SEMESTER_PREFIX}-${_CPSC_44500.id}`,

	'course':_CPSC_44500,
	'instructor':_INSTRUCTOR_ERIC_POGUE,
	'calendar':_DEFAULT_CALENDAR
}

const _CLASS_SECTION_LIST = [
	_FA24_CPSC_20000_002,
	_FA24_CPSC_36000_001,
	_FA24_CPSC_44000_001,
	_FA24_CPSC_49200_004,
	_FA24_CPSC_49200_001,
	_XXXX_CPSC_24500,
	_XXXX_CPSC_24700,
	_XXXX_CPSC_36000,
	_XXXX_CPSC_44500,
]