import { xyz_n_1of6_lists, xyz_n_4of6, xyz_n_1of6, tLab } from './SL00000Sprint00'
import { submissionPercentage, orderedListSlide, tDiscussionBreakout, breakoutStandard } from './SLSprint00'

import { checklistAnnouncementsPreworkAndAgenda } from './SL00000Sprint00'
import { retrospectiveIntroduction } from './SLSprint00'
import { tPrework } from './SLSprint00'
import { completeDeck } from './SL00000Sprint00'
import { sprintDemos, sprintDemosIntro, demoAssignment } from './SLSprint00'
import { tDemos } from './SL00000Sprint00'

import { list20000Sprint06 } from '../ActivityLists/AL20000Sprint06'
import { list20000Sprint07 } from '../ActivityLists/AL20000Sprint07'

import { tExamExpectations, tExam } from './SLSprint00'
import { basicSlide } from './SLSprint00'
import { bulletListSlide } from './SLSprint00'
import { tNoClassToday } from './SL00000Sprint00'

// Todo:
//		'YouTube, Eric’s Trip to Google, The Social Dilemma, and Technology Business Models',
// theSocialDilemma, 
// Introduction to Computer Science (ICS) sprint 7 global values.

const sprint = 7
const activityListPrevious = () => { return list20000Sprint06(sprint-1) } 
const activityList = () => { return list20000Sprint07(sprint) }

// Session 1 of 6: Monday
export const sprint7PlanningExpectations = () => {
	return orderedListSlide('Sprint Planning as a Class', `Sprint ${sprint} Expectations:`, [
		'Similar to previous sprints in that it has a Discussion, Quiz, Lab, and Reflection',
		'Nearly 100% focused on your Class Project',
		`Sprint ${sprint} has an extra week making it three weeks duration`,
		'We miss part of a week due to Thanksgiving',
		'Pulling forward effort from sprint 8 to make sprint 8 more manageable for you (18+18 -> ~28+8)',
		'Our Final Exam will be in person on Wednesday, May 1st with in person attendance required',
		`Sprint ${sprint+1} is directly connected with sprint ${sprint} in that we will be presenting our Class Projects` ])
}
export const ics_7_1of6_PAaA = xyz_n_1of6_lists(sprint)
export const ics_7_1of6 = () => { return xyz_n_1of6(sprint, ics_7_1of6_PAaA.prework, activityListPrevious, ics_7_1of6_PAaA.agenda, ics_7_2of6_PAaA.prework, activityList, sprint7PlanningExpectations) }

// Sprint 7 session 2 of 6: Wednesday
const ics_7_2of6_PAaA = {
	'prework':[
		'Complete through activity 5 prior to next class', '',
		`Be prepared Discussion Board ${sprint}`,
		'Be prepared for Lab & Programming Together' ],
	'announcements':[ 
		'Any announcements?' ],
	'agenda':[
		`Discussion Board ${sprint} as a scrum team`,
		'Review Demo Schedule for Next Class',
		'Prework for Next Class',
		'Lab & Programming Together' ]
}
export const ics_7_2of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_7_2of6_PAaA, sprint, activityList)
	const discussionBreakout = () => { return tDiscussionBreakout(sprint) } 
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_7_3of6_PAaA.prework, sprint, activityList) }
	const reviewDemoSchedule = () => {
		return bulletListSlide('Review Friday’s Demo Schedule', 
			'Let’s review Wednesday’s demo schedule while recalling that demos are an important part of scrum and that they:', [
			'Occur at the beginning of each new sprint ',
			'Are an opportunity to show what was completed in the previous sprint',
			'Are an **easy** and hopefully rewarding experience to show off your work',
			'Provide a chance to see how others solved a problem and to see some of the challenges they faces' ]
		)
	}

	return completeDeck(slideDeck, [ discussionBreakout, preworkNext, reviewDemoSchedule, tLab ])
}

// Session 3 of 6: Friday
const ics_7_3of6_PAaA = {
	'prework':[
		'Complete through activity 7 prior to next class', '',
		'Be prepared for Demos & Retrospectives' ],
	'announcements':[ 
		'Review Final Exam date/time',
		'Review Class Project presentation data/time' ],
	'agenda':[
		`Sprint ${sprint-1} Demos`,
		`Sprint ${sprint-1} Retrospective`,
		'Lab' ]
}
export const ics_7_3of6 = () => { 
	const metricsSubmissionPercentage = () => {
		return submissionPercentage([
			{ name: 'Discussion', due:22, submitted:22 },
			{ name: 'Quiz', due:22, submitted:22 },
			{ name: 'Lab', due:22, submitted:22 },
			{ name: 'Reflection', due:22, submitted:21 }])
	}
	const retrospective = () => {
		return orderedListSlide('Class Retrospective',
			'Feedback from Assignments & Reflections', [
			`Wow! Great Sprint ${sprint-1} submission percentage`,
			'Grading is complete and posted', 
			'Thank you for your reflection comments',
			`Numerous comments about sprint ${sprint-1} being easier... but that’s not true in my opinion`,
			'Can we make one final push to the end?' ])
	}
	const scrumTeamRetrospective = () => {
		return orderedListSlide('Scrum Team Retrospective',
			'What is the highest priority Continuous Improvement action for your team?', [
			`What will your team need to do to guarantee Sprint ${sprint+1} planning is complete on the first day of the Sprint?`,
			'Do we need to do something different to make sure that team members are here in person for sprint planning?',
			'What is the highest priority Continuous Improvement item for your team... will you make it a story in the future?' ])
	}

	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_7_3of6_PAaA, sprint, activityList)
	const retrospectiveInto = () => { return retrospectiveIntroduction(sprint)}
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_7_4of6_PAaA.prework, sprint, activityList) }
	return completeDeck(slideDeck, [ sprintDemosIntro, sprintDemos, tDemos, demoAssignment, retrospectiveInto, metricsSubmissionPercentage, retrospective, scrumTeamRetrospective, preworkNext])
}

// Sprint 7 session 4 of 6: Monday
const ics_7_4of6_PAaA = {
	'prework':[
		'Complete through activity 8 prior to next class', '',
		'Be prepared topic discussion',
		'Be prepared Lab' ],
	'announcements':[ 
		'No class Wednesday and Friday this week',
		'Review Final Exam date/time',
		'Review Class Project presentation data/time' ],
	'agenda':[
		'Sprint Progress Polling',
		'Social Media and Technology Business Models',
		'Lab' ]
}
export const ics_7_4of6 = () => { 
	const breakoutSocialMedia = () => { 
		return breakoutStandard( 
			'Breakout: Social Media, YouTube, and Technology Business Models', 
			'In this breakout session we will explore:', [
				'Social Media, YouTube, and Technology Business Models',
				'YouTube, Eric’s Trip to Google, The Social Dilemma, and Technology Business Models']
		)
	}

	return xyz_n_4of6(sprint, ics_7_4of6_PAaA, ics_7_5of6_PAaA.prework, activityList, breakoutSocialMedia)
}

// Sprint 7 session 5 of 6: Wednesday
const ics_7_5of6_PAaA = {
	'prework':[
		'Complete through activity 8 prior to next class' ],
	'announcements':[ 
		'Recall that our Final Exam is next Wednesday in class with in-person attendance required' ],
	'agenda':[
		'Sprint Progress Polling',
		'Software Business Models, YouTube, and the competition for your eyes',
		'Lab' ]
}
export const ics_7_5of6 = () => { return [ tNoClassToday ] }

// Sprint 7 session 6 of 6: Friday
export const ics_7_6of6 = () => { return [ tNoClassToday ] }

// Sprint 7 session 7 of 6: Monday
export const ics_7_7of6_PAaA = {
	'prework':[
		'Be prepared for Lab' ],
	'announcements':[
		'Wednesday is our Final Exam (in-person attendance required)' ],
	'agenda': [ 
		'Confirm Final Exam, Sprint 8 Planning, and Class Project Presentation, Dates and Times',
		'Globalization, Software Development, and Working with India',
		'Lab' ]
}

export const confirmFinalExamAndPresentationTimes = () => {
	return orderedListSlide('Confirm Final Exam and Class Project presentation times',
		'Final Exam, Sprint 8 Planning, and Class Project Presentation, Dates and Times', [
		'Final Exam (in-person attendance required)',
		'Sprint 8 Planning',
		'Confirm Class Project Presentation (in-person attendance required)' ] )
}
export const workingWithIndia = () => {
	return orderedListSlide('Globalization and Working with India', 
		'Globalization, Software Development, and Working with India', [ ] )
}
export const ics_7_7of6 = () => {
/*
	const breakout = () => { 
		return breakoutStandard( 
			'Breakout: Security', 
			'In this breakout session we will explore Security:', [
				'Information Security and Confidentiality, Availability, and Integrity',
				'Access, Authentication, Authorization, CAPTCHA',
				'Malicious Code, Malware, Worms, Trojan Horses, Phishing, and DoS',
				'Cryptography, Encryption/Decryption, ciphers, public-key cryptography, and digital signatures',
				'Protecting Online Information',
				'Security and Usability Tradeoffs' ]
		)
	}
*/
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_7_7of6_PAaA, sprint, activityList)
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_7_8of6_PAaA.prework, sprint, activityList) }
	return completeDeck(slideDeck, [ confirmFinalExamAndPresentationTimes, workingWithIndia, preworkNext, tLab ]) 
}

// Sprint 7 session 8 of 6: Wednesday
const ics_7_8of6_PAaA = {
	'prework': [ 
		'Complete through activity 8', '',
		`Be prepared for your Final Exam` ],
	'announcements': [ 	
		`All sprint ${sprint} assignments due Sunday`,
		`All sprint ${sprint+1} assignment due next Wednesday`, ],
	'agenda': [ 
		'Confirm Class Project Presentation Time',
		'Review Class Project presentation Expectations',
		'Final Exam' ]
}

export const confirmPresentationTimes = () => {
	return orderedListSlide('Confirm Final Exam and Class Project presentation times',
		'Confirm Class Project Presentation Time', [ ] )		
}
export const finalProjectPresentation = () => {
	return orderedListSlide('Class Project Presentation Expectations ', `Recall that your final project presentation should including a/an:`, [
		'Introduce of your yourself and your project',
		'Show your project proposal and provide an overview of your product',
		'Demonstrate several “similar in complexity” features',
		'Demonstrate one or more “more complex” features',
		'Overview of the project source code',
		'Describe anything additional that you intend to deliver by the end of class' ])
}
export const ics_7_8of6 = () => { 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_7_8of6_PAaA, sprint, activityList)
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_7_9of6_PAaA.prework, sprint, activityList) }
	return completeDeck(slideDeck, [ confirmPresentationTimes, finalProjectPresentation, preworkNext, tExamExpectations, tExam ])
}

export const ics_7_9of6_PAaA = { 
	'prework':[
		`Be prepared for Class Project presentations if you are presenting Friday`,
		`Be prepared for a sprint ${sprint+1} planning` ],
	'announcements':[ 
		`All sprint ${sprint} assignments due Sunday`,
		`All sprint ${sprint+1} assignment due Wednesday`,
		`If you are presenting today, your sprint ${sprint} and sprint ${sprint+1} assignments are due Sunday`,
		'You should have received your official email requesting class feedback' ],
	'agenda':[
		`Review of sprint ${sprint} assignments & expectations`,
		'Sprint 8 Planning',
		'Class Project presentations',
		'Globalization and Working with India (continued)'
	 ]
}
export const ics_7_9of6 = () => { 
	const quickSprint7PlanningReview = () => { return basicSlide(`Review of sprint ${sprint} assignments & expectations`, [
		`Let’s look at our ${sprint} assignments and remaining Class Project presentation schedule.` ])}
	const quickSprint8PlanningReview = () => { return basicSlide(`Review of sprint ${sprint+1} assignments & expectations`, [
		`Let’s look at our ${sprint+1} assignments and remaining Class Project presentation schedule.` ])}
	const nextSteps = () => { return tPrework('Next Steps', [ 
		`All sprint ${sprint} assignments due Sunday`,
		'Remaining Class Project presentations next week on...'], 
		sprint, activityList) }
	const finalComments = () => {
		return orderedListSlide('For those of you who presented today... Thank you!', '', [
			'Thank you for making this a wonderful experience for me',
			'Don’t hesitate to reach out in the future',
			'I hope to see you in a future class',
			'Very nice work! I am impressed with what you have accomplished.' ])
	}

	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_7_9of6_PAaA, sprint, activityList)
	return completeDeck(slideDeck, [ quickSprint7PlanningReview, quickSprint8PlanningReview, finalProjectPresentation, nextSteps, workingWithIndia, finalComments ]) 
}