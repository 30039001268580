export const _CALENDAR_FALL_2024_08_26_16 = { 
	'description':'Fall 2024',
	'meetingDates':'Monday, August 26th through Friday, December 13th',
	'sprintDates': [
		{ 'start':'Mon Aug 26 2024 08:00:00 GMT-0500', 'end':'Sun Sep 08 2024 23:59:00 GMT-0600', 'notes':'Martin Luther King Day' },
		{ 'start':'Mon Sep 09 2024 08:00:00 GMT-0500', 'end':'Sun Sep 22 2024 23:59:00 GMT-0600', 'notes':'' },
		{ 'start':'Mon Sep 23 2024 08:00:00 GMT-0500', 'end':'Sun Oct 06 2024 23:59:00 GMT-0600', 'notes':'' },
		{ 'start':'Mon Oct 07 2024 08:00:00 GMT-0500', 'end':'Sun Oct 20 2024 23:59:00 GMT-0600', 'notes':'Fall Break' },
		{ 'start':'Mon Oct 21 2024 08:00:00 GMT-0500', 'end':'Sun Nov 03 2024 23:59:00 GMT-0600', 'notes':'Midterm Exam' },
		{ 'start':'Mon Nov 04 2024 08:00:00 GMT-0500', 'end':'Sun Nov 17 2024 23:59:00 GMT-0600', 'notes':'' },
		{ 'start':'Mon Nov 18 2024 08:00:00 GMT-0500', 'end':'Sun Dec 08 2024 23:59:00 GMT-0600', 'notes':'Thanksgiving Holiday' },
		{ 'start':'Mon Dec 09 2024 08:00:00 GMT-0500', 'end':'Wed Dec 11 2024 23:59:00 GMT-0600', 'notes':'Final Exams / Presentations' }
	]
}
