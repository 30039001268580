import React from 'react'

import { scrumIntro, learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing, estimated } from './AL00000Sprint00'
import { getClassTitle, getModuleDescription } from '../DataAndAPIs/ClassSections'
import { matchMakerGoLink } from '../Activities/Matchmaker'

import { internalLink } from '../DataAndAPIs/Links'
import { toolsOfTheTradeLink } from '../Activities/ToolsOfTheTrade'
import { HelloWorldWebLink } from '../Activities/HelloWorld' 
import { HelloWorldGoLink } from '../Activities/HelloWorld'
import { oreillyPlaylistPTT } from '../DataAndAPIs/Links'

export const al36000Sprint01 = (classSectionID, sprint) => {
	return ( <div>
		<h3>{getClassTitle()}</h3>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>
		
		<p>Does the potential of building beautiful and inspirational software intrigue you? Can your software be beutiful and 
		inspirational if no one but you sees and uses it? Would you like to understand what it takes to distribute your applications 
		to real customers? If these questions exite you, you are in the right place.</p>

		<p><em>Welcome to Programming Tools and Techniques!</em> This course builds on the software development capabilities you 
		already have while introducing you to the world delivering high quality sophisticated software products to real customers. 
		Deploying applications to real customers while working within and accross teams to develop, debug, verify, deploy, and 
		validate software accross development, test, and production environments is often the most complex and challenging part
		of creating beautiful and inspirational software. </p>
		
		<p>Let’s start our journey by reexamining the software development tools and techniques you have learned in the past. What 
		does it takes to write, test, debug, fix, and execute software on our local computer. To add some spice to our recap, let’s
		practicing this while learning a new programming language, Go. What Go was designed to focus on the challenges that existing tools
		were were facing deploying in developing, debugging, verifying, deploying, and validating software products globally.</p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Effectively participate in a blended classroom environment and as part of a Scrum team</li>
			<li>Explain why agile development and scrum have impacted our choice of programming techniques and languages</li>
			<li>Compare and contrast key software development tool and language features</li>
			<li>Explain how Go is optimized for getting products to customers quickly and effectively</li>
			<li>Understand Continous Integration / Continuous Delivery (CI/CD)</li>
			<li>Understand why immutability, minimizing dependancies, and statelessness have become critical for for CI/CD</li>
		</ul>

		{scrumIntro(classSectionID, sprint)}

		{list36000Sprint01(sprint)}

		{closing(sprint)}
	</div> )
}

export const list36000Sprint01 = (sprint) => {
	const conceptsAndPractices = () => { return internalLink('Object-Oriented Programming Concepts and Practices', '/activity-oop/concepts-and-practices') }

	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, matchMakerGoLink())}
			<li>Complete {HelloWorldWebLink()}</li>
			<li><em>Complete the Introduction assignment by making your post and responding to at least two of your classmates</em></li>

			<li>Review {conceptsAndPractices()}</li>
			<li>Within {oreillyPlaylistPTT()} read “Introducing Go” chapters 1 though 6 </li>
			
			{initialPost(sprint)}
			<li>Complete {toolsOfTheTradeLink()} including Go{estimated('2 hours')}</li>
			<li>Complete {HelloWorldGoLink()}</li> 

			{standardActivitiesClosing(sprint, matchMakerGoLink(), '6 hours')}
		</ol>
	</div> )
}

// BugBug: There is no Programming Together playlist for this class so the closing link does not work. 
// BugBug: Link for Hello World with Go broken.  Fix this link.

// Todo: Consider where Authentication, Authorization, Auth0, Databases, and MongoDB Atlas should go in the class. 