import { xyz_n_1of6_lists, xyz_n_1of6 } from './SL00000Sprint00'
import { tUnderConstruction } from './SL00000Sprint00'
import { tNoClassToday } from './SL00000Sprint00'

import { list36000Sprint06 } from '../ActivityLists/AL36000Sprint06'
import { list36000Sprint07, expectedProgress } from '../ActivityLists/AL36000Sprint07'

import { sprint7PlanningExpectations } from './SL20000Sprint07'

import { submissionPercentage } from './SLSprint00'
import { orderedListSlide, tDiscussionBreakout } from './SLSprint00'
import { xyz_n_3of6 } from './SL00000Sprint00'
import { insertInto } from './SLSprintPlanning'
import { xyz_n_4of6 } from './SL00000Sprint00'
import { breakoutStandard } from './SLSprint00'

import { tLab } from './SL00000Sprint00'
import { tPrework } from './SLSprint00'
import { checklistAnnouncementsPreworkAndAgenda } from './SL00000Sprint00'
import { completeDeck } from './SL00000Sprint00'

import { confirmFinalExamAndPresentationTimes, workingWithIndia } from './SL20000Sprint07'
import { tExamExpectations, tExam } from './SLSprint00'
import { confirmPresentationTimes, finalProjectPresentation } from './SL20000Sprint07'
import { ics_7_9of6_PAaA } from './SL20000Sprint07'
import { basicSlide } from './SLSprint00'

// Programming Tools & Techniques (PTT) sprint 7 global values.
const sprint = 7
const activityList = () => { return list36000Sprint07() }
const activityListPrevious = () => { return list36000Sprint06(sprint) }

// Session 1 of 6: Monday
const ptt_7_1of6_PAaA = xyz_n_1of6_lists(sprint)
const ptt_7_1of6 = () => { return xyz_n_1of6(sprint, ptt_7_1of6_PAaA.prework, activityListPrevious, ptt_7_1of6_PAaA.agenda, ptt_7_2of6_PAaA.prework, activityList, sprint7PlanningExpectations) }

// Session 2 of 6: Wednesday
const ptt_7_2of6_PAaA = { 
	'prework':[
		`Complete through activity ${expectedProgress[2-1]} prior to next class`, '',
		`Be prepared Discussion Board ${sprint}`,
		'Be prepared for Lab & Programming Together' ],
	'announcements':[ 
		`TBD` ],
	'agenda':[
		'TBD']
}
const ptt_7_2of6 = () => { return [ tUnderConstruction ] }

// Session 3 of 6: Friday
const ptt_7_3of6_PAaA = {
	'prework':[
		`Complete through activity ${expectedProgress[3-1]} prior to next class`, '',
		`Be prepared sprint ${sprint-1} Demos and Retrospectives`,
		'Be prepared for Discussion 6 as a team' ],
	'announcements':[ 
		'Review Final Exam date/time',
		'Review Class Project presentation data/time' ],
	'agenda':[
		`Sprint ${sprint-1} Demos`,
		`Sprint ${sprint-1} Retrospective`,
		`Breakout for Sprint ${sprint-1} Retrospective`,
		'Prework for Next Class' ]
}
const ptt_7_3of6 = () => {
	const metricsSubmissionPercentage = () => {
		return submissionPercentage([
			{ name: 'Discussion', due:8, submitted:8 },
			{ name: 'Quiz', due:8, submitted:8 },
			{ name: 'Lab', due:8, submitted:8 },
			{ name: 'Reflection', due: 8, submitted: 8 }])
	}
	const retrospective = () => {
			return orderedListSlide('Class Retrospective',
			'Feedback from Assignments & Reflections', [
				'Perfect submission percentage (again)... thank you!',
				'All assignments are graded and posted... lab grades will be posted soon',
				'Very nice work!' ])
	}
	const discussionBreakout = () => { return tDiscussionBreakout(sprint) } 
	const slideDeck =  xyz_n_3of6(sprint, ptt_7_3of6_PAaA, ptt_7_4of6_PAaA.prework, activityList, metricsSubmissionPercentage, retrospective)
	
	return insertInto(slideDeck, [discussionBreakout], 14)
}

// Session 4 of 9: Monday
const ptt_7_4of6_PAaA = {
	'prework':[
		'Complete through activity 8 prior to next class' ],
	'announcements':[ 
		'Recall that our Final Exam is next Wednesday in class with in-person attendance required' ],
	'agenda':[
		'Sprint Progress Polling',
		'Software Business Models, YouTube, and the competition for your eyes',
		'Lab' ]
}
const ptt_7_4of6 = () => { 
	const breakoutSocialMedia = () => { 
		return breakoutStandard( 
			'Breakout: Social Media, YouTube, and Technology Business Models', 
			'In this breakout session we will explore:', [
				'Social Media, YouTube, and Technology Business Models',
				'YouTube, Eric’s Trip to Google, The Social Dilemma, and Technology Business Models']
		)
	}

	return xyz_n_4of6(sprint, ptt_7_4of6_PAaA, ptt_7_5of6_PAaA.prework, activityList, breakoutSocialMedia)
}

const ptt_7_5of6_PAaA = {
	'prework':[
		'Be prepared for Lab' ],
	'announcements':[
		'Any announcements?' ],
	'agenda': [ 
		'Confirm Final Exam Time',
		'Confirm Class Project presentation time',
		'Practical Diversity and Working with India',
		'Lab' ]
}
const ptt_7_5of6 = () => { return [ tNoClassToday ] }

const ptt_7_6of6 = () => { return [ tNoClassToday ] }

// Sprint 7 session 7 of 6: Monday
const ptt_7_7of6_PAaA = {
	'prework':[
		'Be prepared for Lab' ],
	'announcements':[
		'Wednesday is our Final Exam (in-person attendance required)' ],
	'agenda': [ 
		'Confirm Final Exam, Sprint 8 Planning, and Class Project Presentation, Dates and Times',
		'Globalization, Software Development, and Working with India',
		'Lab' ]
}

const ptt_7_7of6 = () => {
		const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_7_7of6_PAaA, sprint, activityList)
		const preworkNext = () => { return tPrework('Prework For Next Class', ptt_7_8of6_PAaA.prework, sprint, activityList) }
		return completeDeck(slideDeck, [ confirmFinalExamAndPresentationTimes, workingWithIndia, preworkNext, tLab ]) 
}

// Sprint 7 session 8 of 6: Wednesday
const ptt_7_8of6_PAaA = {
	'prework': [ 
		'Complete through activity 7', '',
		`Be prepared for your Final Exam` ],
	'announcements': [ 	
		`All sprint ${sprint} assignments due Sunday`,
		`All sprint ${sprint+1} assignment due next Wednesday`, ],
	'agenda': [ 
		'Confirm Class Project Presentation Time',
		'Final Exam' ]
}

//const ptt_7_8of6 = () => { return [ tUnderConstruction ] }

const ptt_7_8of6 = () => { 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_7_8of6_PAaA, sprint, activityList)
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_7_9of6_PAaA.prework, sprint, activityList) }
	return completeDeck(slideDeck, [ confirmPresentationTimes, finalProjectPresentation, preworkNext, tExamExpectations, tExam ])
}

const ptt_7_9of6_PAaA = ics_7_9of6_PAaA

const ptt_7_9of6 = () => { 
	const quickSprint7PlanningReview = () => { return basicSlide(`Review of sprint ${sprint} assignments & expectations`, [
		`Let’s look at our ${sprint} assignments and remaining Class Project presentation schedule.` ])}
	const quickSprint8PlanningReview = () => { return basicSlide(`Review of sprint ${sprint+1} assignments & expectations`, [
		`Let’s look at our ${sprint+1} assignments and remaining Class Project presentation schedule.` ])}
	const nextSteps = () => { return tPrework('Next Steps', [ 
		`All sprint ${sprint} assignments due Sunday`,
		'Remaining Class Project presentations next week on...'], 
		sprint, activityList) }
	const finalComments = () => {
		return orderedListSlide('For those of you who presented today... Thank you!', '', [
			'Thank you for making this a wonderful experience for me',
			'Don’t hesitate to reach out in the future',
			'I hope to see you in a future class',
			'Very nice work! I am impressed with what you have accomplished.' ])
	}

	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_7_9of6_PAaA, sprint, activityList)
	return completeDeck(slideDeck, [ quickSprint7PlanningReview, quickSprint8PlanningReview, finalProjectPresentation, nextSteps, workingWithIndia, finalComments ]) 
}


export const ptt_7_slidedeck = [ ptt_7_1of6, ptt_7_2of6, ptt_7_3of6, ptt_7_4of6, ptt_7_5of6, ptt_7_6of6, ptt_7_7of6, ptt_7_8of6, ptt_7_9of6 ]