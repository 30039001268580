import { list36000Sprint01 } from '../ActivityLists/AL36000Sprint01'

import { sprint1_class_3_PAaA, sprint1_class_3 } from './SL00000Sprint00'
import { checklistAnnouncementsPreworkAndAgenda, completeDeck, tLab } from './SL00000Sprint00'
import { tPrework, tQuizExpectations, tQuiz, tRecap, breakoutStandard } from './SLSprint00'

import { xyz_1_2of6 } from './SL00000Sprint01'
import { xyz_1_1of6 } from './SL00000Sprint01'

import { ptt_2_1of6_PAaA } from './SL36000Sprint02'

// Introduction to Computer Science (ICS) sprint 1 global values.
const sprint = 1
const activityList = () => { return list36000Sprint01(sprint) }

// Sprint 1 session 1 of 6: Monday
const ptt_1_1of6_PAaA = {
	'prework':[
		'Review Welcome Message', 
		'Review Blended Learning',
		'Review Scrum',
		'Create Discord Account and set up Scrum Team Discord Server' ],
	'announcements':[ 
		'Friday’s class will be remote via Zoom',
		'Sorry that some of the first names on my spreadsheet were incorrect - I believe they are fixed now',
		'Community Hour is today at high noon'],
	'agenda':[ 
		'Progress Polling',
		'Introductions (continued)',
		'Sprint 1 Planning (continued)',
		'Scrum Team Breakout',
		'Prework for Next Class',
		'Recap' ]
}
export const ptt_1_1of6 = () => { return xyz_1_1of6(activityList, ptt_1_1of6_PAaA.prework) }

// Session 2 of 6: Wednesday
const ptt_1_2of6_PAaA = {
	'prework':[
		'Review Welcome Message', 
		'Review Blended Learning',
		'Review Scrum',
		'Create Discord Account and set up Scrum Team Discord Server' ],
	'announcements':[ 
		'Friday’s class will be remote via Zoom',
		'Sorry that some of the first names on my spreadsheet were incorrect - I believe they are fixed now',
		'Community Hour is today at high noon'],
	'agenda':[ 
		'Progress Polling',
		'Introductions (continued)',
		'Sprint 1 Planning (continued)',
		'Scrum Team Breakout',
		'Prework for Next Class',
		'Recap' ]
}
export const ptt_1_2of6 = () => { return xyz_1_2of6(sprint, ptt_1_2of6_PAaA, ptt_1_3of6_PAaA, activityList) }

// Session 3 of 6: Friday
const ptt_1_3of6_PAaA = sprint1_class_3_PAaA
export const ptt_1_3of6 = () => { return sprint1_class_3(sprint, activityList, sprint1_class_3_PAaA, ptt_1_4of6_PAaA.prework) } //{ return [sprint1_class_3(sprint, activityList, sprint1_class_3_PAaA, ptt_1_4of6_PAaA.prework)] }

// Session 4 of 6: Monday
const ptt_1_4of6_PAaA = {
	'prework':[
		'Complete through activity 11 prior to next class', '',
		'Be prepared to discuss the Go language', 
		'Be prepared for Lab'],
	'announcements':[ 
		'Welcome back for week 2!',
		'Today is a busy day' ],
	'agenda':[ 
		'Sprint Progress Poll',
		'Discussion 1 & Lab',
		'Preview of Wednesday’s topic' ]
}
export const ptt_1_4of6 = () => { return [tLab] }

// Session 5 of 6: Wednesday
const ptt_1_5of6_PAaA = {
	'prework':[
		'Complete through activity 12 prior to next class', '',
		'Make your initial post for Discussion 1',
		'Be prepared for “Go vs. Java vs. Python vs. JavaScript” Breakout',
		'Be prepared for Lab',
		'Be prepared for Quiz 1'],
	'announcements':[
		'Your initial Discussion 1 post was due Monday',
		'All sprint 1 activities and assignments due Sunday'],
	'agenda':[
		'Sprint Progress Polling',
		'Breakout: Go vs. Java vs. Python vs. JavaScript',
		'Lab', 
		'Prework for Next Class',
		'Quiz 1']
}
export const ptt_1_5of6 = () => { 
	const breakoutFileSystemsDirectoriesAndFile = () => {
		return breakoutStandard(
			'Breakout: Go vs. Java vs. Python vs. JavaScript', 
			'In this breakout session on Go vs. Java vs. Python vs. JavaScript your team will:', [
				'How does Go handle libraries, inheritance, member variables, functional programming, and concurancy?',
				'How does Java handle libraries, inheritance, member variables, functional programming, and concurancy?',
				'How does Python and JavaScript handle libraries, inheritance, member variables, functional programming, and concurancy?',
				'How would we utilize graphical and command line tools to view and update File Systems?'])
	}

	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_1_5of6_PAaA, sprint, activityList)
	const poll = () => { return tPrework('Sprint Progress Polling', ptt_1_5of6_PAaA.prework, sprint, activityList) }
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_1_6of6_PAaA.prework, sprint, activityList) }
	const breakout = () => { return breakoutFileSystemsDirectoriesAndFile() }
	const recap = () => { return tRecap(ptt_1_5of6_PAaA.agenda)}
	const quiz = () => { return tQuiz(sprint) }

	return completeDeck(slideDeck, [poll, breakout, tLab, tQuizExpectations, quiz, recap, preworkNext])
}

// Session 6 of 6: Friday
const ptt_1_6of6_PAaA = {
	'prework': [
		'Complete through activity 14 and working on 15 prior to next class', '',
		'Be prepared for Lab & Programming Together',
		`Be prepared to submit all sprint ${sprint} assignments Sunday!` ],
	'announcements':[
		`All sprint ${sprint} assignments due Sunday!`],
	'agenda':[
		'Quiz 1',
		'Lab']
}
export const ptt_1_6of6 = () => { 
	const slideDeck =  checklistAnnouncementsPreworkAndAgenda(ptt_1_6of6_PAaA, sprint, activityList)
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_2_1of6_PAaA.prework, sprint, activityList) }
	const quizExpectations = () => { return tQuizExpectations() }
	const quiz = () => { return tQuiz(sprint) }

	return completeDeck(slideDeck, [preworkNext, quizExpectations, quiz, tLab])
}