import React from 'react'

import { makeNavigationLink } from './ActivityLink'
import { azureStaticWebApps, nodeJSQuickStart } from '../DataAndAPIs/Links'

const renderMERNaIaC = () => {
	return ( <div><br />
		<h3>{MERNaIaC.name}</h3>
		<h5>Summary</h5>
		<p>{MERNaIaC.name} is an activity where we will create a MERN (MondgoDB, Express, React, Node.js) application
			on Microsoft Azure utilizing IaC (Infrastructure as Code).</p>
		<h5>Requirements</h5>

		<em>Requirement 1</em>
		<p>Review how to create a MERN application on Azure the “old fassioned” way using the Azure <em>portal</em>:</p>
		<ol type='n'>
			<li>Review the “Deploy an app with no front-end framework” QUICKSTART from {azureStaticWebApps()}</li>
			<li>Review the “Deploy a React app” QUICKSTART from {azureStaticWebApps()}</li>
			<li>Review the {nodeJSQuickStart()}</li>
			<li>Consider how you would enhance the “not front-end” application so that it calls an API on the Node.js server</li>
		</ol>

		<em>Requirement 2</em>
		<p>Create and deploy each of the following using the <em>Azure portal</em>:</p>
		<ol type='n'>
			<li>Client “no front-end framework” website</li>
			<li>Client React website</li>
			<li>Server Node.js API website</li>
			<li>Enhance the “no front-end” website by calling the API on the Node.js server</li>
		</ol>

		<em>Requirement 3</em>
		<p>Create and deploy each of the following utilizing the <em>Azure CLI</em> instead of the portal:</p>
		<ol type='n'>
			<li>Client “no front-end framework” website</li>
			<li>Client React website</li>
			<li>Server Node.js API website</li>
			<li>Enhance the “no front-end” website by calling the API on the Node.js server</li>
		</ol>

		<em>Requirement 4</em>
		<p>Create a Python or Bash script to fully automate the same set of deployments:</p>
		<ol type='n'>
			<li>Client “no front-end framework” website</li>
			<li>Client React website</li>
			<li>Server Node.js API website</li>
			<li>Enhance the “no front-end” website by calling the API on the Node.js server</li>
		</ol>
	</div> )
}
 
export const MERNaIaC = { 'name':'MERNa Infrastructure as Code (IaC)', 'route':'merna-iac', 'render':renderMERNaIaC }
export const MernaIaCLink = () => { return makeNavigationLink(MERNaIaC) }