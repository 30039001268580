import React from 'react'

import { initialPost, standardActivitiesClosing, closing } from './AL00000Sprint00'
import { internalLink, JSONWebServicesTutorial } from '../DataAndAPIs/Links'
import { healthRiskCalculatorTeamWebLink } from '../Activities/HealthRiskCalculatorTeam'
import { externalLink } from '../DataAndAPIs/Links'
import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { sprintEndDateWithoutTime } from '../CalendarAndSchedule/SprintDates'
import { scheduleLink, productBacklog, sprintPlanning, userStory } from '../DataAndAPIs/Links'


const azureStaticWebApps = () => { return externalLink('Azure Static Web Apps', 'https://learn.microsoft.com/en-us/azure/static-web-apps/?WT.mc_id=build2020_swa-docs-cxa')}
export const al44000Sprint04 = () => {
	let sprint = 4
	return ( <div>
		<h5>Sprint 4: {getModuleDescription(sprint-1)}</h5>

		<p>We are on a mission in spint {sprint}. It will not be easy, but done right we will set ourselves us 
		up to work with a Software Systems Capstone team on a sophisticated product that will be used by real 
		users and can be added to your portfolio and resume.</p>

		<p>The concepts that we will need to understand in order to complete our mission are included below in our Learning Objectives:</p>
		<ul style={{listStyleType:'square'}}>
			<li>Utilize Agile Software Development Lifecycle (SDLC) Rolls including Scrum Master and Scrum Product Owner</li>
			<li>Demonstrate Requirements gathering through {sprintPlanning()}, {userStory()}(s), {productBacklog()}, and a Sprint Backlog</li>
			<li>Utilize our SaaS architecture including HTML, CSS, and JavaScript clients with server APIs in Node.js</li>
			<li>Understand Testing and Test-Driven Development</li>
		</ul>

		<p>Now on to the mission. The sprint {sprint} mission comes in two parts. First, you and your team will utilize  
		Scrum and our SaaS architecture to deliver {healthRiskCalculatorTeamWebLink()}. Second, you will commit to which 
		Software Systems Capstone team and product you will be working on for the second half of the semester.</p>

		<p>Mission Briefing: </p>
		<ul style={{listStyleType:'square'}}>
			<li>Who: You and your Scrum team</li>
			<li>What: Deliver {healthRiskCalculatorTeamWebLink()} utilizing Scrum and our SaaS architecture</li>
			<li>When: During sprint {sprint} which ends <em>{sprintEndDateWithoutTime(sprint-1)}</em> and has the folloing class {scheduleLink(sprint)}</li>
			<li>Where: In class, outside of class with your scrum team, and working independantly</li>
			<li>Why: So that we can effectively work as a Scrum team on our Class Product for the second half of the semester</li>
		</ul>

		<p>Now that you have your mission, let’s get to work. You will need all of the tools and knowledge that you have
		learned in the first three sprints to complete this mission. Good luck!</p>
		
		{list44000Sprint04(sprint)}
		{closing(sprint)}
	</div> )
}

export const list44000Sprint04 = (sprint) => {
	const foxChapter7Lecture = () => { return internalLink('lecture', '/activity/fox-chapter-07') }
	const foxChapter8Lecture = () => { return internalLink('lecture', '/activity/fox-chapter-08') }
	return ( <div>
		<p>Below is the activities list that will help us complete our sprint {sprint} mission:</p>
		<ol>
			<li><em>Review sprint {sprint} assignments including Discussion {sprint}, Quiz {sprint}, Lab {sprint}, Reflection {sprint}, and Lab Demo</em></li>
			<li>As needed, review “Engineering Software as a Service” chapter 7 on Requirements and the associated {foxChapter7Lecture()}</li>
			<li>Complete {healthRiskCalculatorTeamWebLink()} Requirement 1 and submit <em>Lab 1 - Planning</em></li>
			<li>As a team, align with a Software Systems Capstone team to work on your Class Product</li>

			{initialPost(sprint)}
			<li><em>Commit to working with a Capstone team for your Class Project in class on Monday, October 14th at 1 pm</em></li>
			<li>Complete {JSONWebServicesTutorial()}</li>
			<li>Read Chapter 8 of Fox on Test-Driven Development and review the associated class {foxChapter8Lecture()}</li>
			{/*<li>Review and understand Serverless Computing by analyzing {ServerlessAzure()}, {ServerlessGoogle()}, and {ServerlessAWS()}</li>*/}

			<li>Review {azureStaticWebApps()} and complete “QUICKSTART: Deploy an app with no front-end framework” utilizing the “Azure portal” </li>
			{standardActivitiesClosing(sprint, healthRiskCalculatorTeamWebLink(), '6 hours per team member')}
		</ol>
	</div> )
}

// Certified for FA24 on October 6, 2024





