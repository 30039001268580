import React from 'react'

import { learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing, estimated } from './AL00000Sprint00'
import { oreillyPlaylistSE } from '../DataAndAPIs/Links'

import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { assignmentPortfolioWithNodeJSLink } from '../Activities/WebAssignmentPortfolioWithNodeJS'
import { assignmentPortfolioLink } from '../Activities/WebAssignmentPortfolio'
import { azureWebsiteLink } from '../Activities/AzureWebsite'
import { webDiceRollerLink } from '../Activities/WebDiceRoller'
import { JavaScriptW3C, RegularExpressionsW3C, HTMLW3C, CSSW3C } from '../DataAndAPIs/Links'
import { webDiceRollerPlusLink } from '../Activities/WebDiceRollerPlus';

export const al36000Sprint03 = () => {
	const sprint = 3
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>
		<p><em>Welcome to sprint 3.</em> This sprint we will be focused on implemenenting solid release management 
		practices to create Azure Static and Node.js Azure based servers that fully support development, test, and 
		production environments. We will also start our journey to develop some web base client web applications</p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Create both Static and  Node.js based web application on Azure</li>
			<li>Implement both client and server based functionality within Node.js running both locally and in Azure</li>
			<li>Create and utilize development, test, and production environments including secrets</li>
			<li>Understand what would need to be done in order to create a similary Go environment hosted on Azure</li>
		</ul>

		{list36000Sprint03(sprint)}
		{closing(sprint)}
	</div> )
}

export const list36000Sprint03 = (sprint) => {
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, webDiceRollerPlusLink())}
			<li>Complete {azureWebsiteLink()}{estimated('2 hours')}</li>
			<li>Complete {assignmentPortfolioLink()}</li>
			<li>Complete the W3Schools {HTMLW3C()} and {CSSW3C()} tutorials{estimated('4 hours')}</li>
			<li>Within {oreillyPlaylistSE()} scan “Learning Web Development” Preface through Chapter 4</li>
			{initialPost(sprint)}
			<li>Complete {JavaScriptW3C()} tutorial up to {RegularExpressionsW3C()}</li>
			<li>Complete {webDiceRollerLink()}</li>
			<li>Briefly review {assignmentPortfolioWithNodeJSLink()} with development, test, and production enviornments</li>
			{standardActivitiesClosing(sprint, webDiceRollerPlusLink(), '6 hours')}
		</ol>
	</div> )
}


