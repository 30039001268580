
import ReactPlayer from 'react-player'
import { makeNavigationLink } from './ActivityLink'

export const reactPlayerVideoLink = (header, url) => {
	return ( <div><h5>{header}</h5><ReactPlayer url={url} controls={true} width={1024} height={576} /></div> )
}

const scrumIn7MinName = 'Introduction to Scrum in 7 Minutes'
const renderScrumIn7Minutes = () => { return reactPlayerVideoLink(scrumIn7MinName, 'https://www.youtube.com/watch?v=9TycLR0TqFA') }
export const scrumIn7Minutes = { 'name':scrumIn7MinName, 'route':'scrum-in-7-minutes', 'render':renderScrumIn7Minutes }
export const scrumIn7MinutesLink = () => { return makeNavigationLink(scrumIn7Minutes) }