import React from 'react'

import { externalLink } from '../DataAndAPIs/Links'
import { estimated } from './AL00000Sprint00'
import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing } from './AL00000Sprint00'

import { goWebServerLinkWithDockerLink } from '../Activities/GoWebServer'
import { finalProjectProposalLink } from '../Activities/FinalProjectProposal'
import { diceRollerAPIWithNodeLink } from '../Activities/WebAssignmentPortfolioWithNodeJS'
import { goWebServerLink } from '../Activities/GoWebServer'
import { docker } from '../DataAndAPIs/Links'

export const al36000Sprint05 = () => {
	const sprint = 5
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>
		<p>Sprint {sprint}. Where does the time go?</p>
		<p>This sprint we will continue to be focused DevOps and Configuraiton Managment while expanding 
		our focus to include {docker()} as a tool for packaging and hosting applications.</p>

		<p>It’s also time for us to think about our final projects. How would you like to finish the class by creating 
		something that makes you proud? What type of a final project would you enjoy creating? Is there a project that would 
		both be interesting to create and be something you could add to your professional portfolio to show as you are looking 
		for an internship or job? You have the power.</p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Understand and utilize Docker containers to package and host applications</li>
			<li>Explain Application Programming Interaces (APIs) using GraphQL</li>
			<li>Implement basic REST APIs using Docker and Go</li>
			<li>Explain and design GraphQL APIs</li>
		</ul>

		{list36000Sprint05(sprint)}
		{closing(sprint)}
	</div> )
}

export const list36000Sprint05 = (sprint) => {
	const dockerTutorial = () => { return externalLink('Docker tutorial: Get started with Docker', 
		'https://www.infoworld.com/article/2176798/docker-tutorial-get-started-with-docker.html')}
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, goWebServerLinkWithDockerLink())}
			<li>Complete {dockerTutorial()}{estimated('2 hours')}</li>
			<li>Review {goWebServerLink()}</li>
			<li>Review {diceRollerAPIWithNodeLink()}</li>
			{initialPost(sprint)}
			<li>Complete {finalProjectProposalLink()}</li>
			{standardActivitiesClosing(sprint, goWebServerLinkWithDockerLink(), '8 hours')}
		</ol>
	</div> )
}
