import React from 'react'

import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing } from './AL00000Sprint00'
import { CSSW3C, HTMLW3C, JavaScriptW3C, externalLink } from '../DataAndAPIs/Links'
import { assignmentPortfolioWithNodeJSLink } from '../Activities/WebAssignmentPortfolioWithNodeJS'
import { JSONWebServicesTutorial, azureStaticWebApps, azureStaticReactWithPortal } from '../DataAndAPIs/Links'

export const al36000Sprint04 = () => {
	const sprint = 4
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>
		<p><em>Welcome to sprint 4.</em> This sprint we will continue to be focused DevOps and Configuraiton Managment while expanding 
		our focus to include Application Programming Interfaces (APIs), REST, and GraphQL. </p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Explain, utilize, and implement Application Programming Interaces (APIs) using REST</li>
			<li>Explain and implement Application Programming Interaces (APIs) using GraphQL</li>
			<li>Understand, implement, and deploy a basic React application</li>
			<li>Host a React applicatio on an Azure static website</li>
		</ul>

		{list36000Sprint04(sprint)}
		{closing(sprint)}
	</div> 	)
}

export const list36000Sprint04 = (sprint) => {
	const react = () => { return externalLink('React', 'https://react.dev/')}
	const reactQuickStart = () => { return externalLink('React Quick Start', 'https://react.dev/learn')}
	const reactTicTacToe = () => { return externalLink('Intro to React - Tic-Tac-Toe', 'https://reactjs.org/tutorial/tutorial.html')}

	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, assignmentPortfolioWithNodeJSLink())} 
			<li>As needed, review {HTMLW3C()}, {CSSW3C()}, and {JavaScriptW3C()} all from W3C</li>
			<li>Complete {JSONWebServicesTutorial()}</li>

			{initialPost(sprint)}
			<li>Focus on CI/CD with React by studying {azureStaticWebApps()}</li>
			<li>Review {react()} and complete {reactQuickStart()} </li>
			<li>Complete {azureStaticReactWithPortal()} </li>
			<li>Complete {reactTicTacToe()}</li>
			{standardActivitiesClosing(sprint, assignmentPortfolioWithNodeJSLink(), '8 hours')}
		</ol>
	</div> )
}