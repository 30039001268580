// Overview: Each course has eight sprints and one activity list per sprint. All activity lists are imported, placed in 
// an array of functions, and then called by the ActivityList function based on the class number. It is assumed that all
// class sestions utilize the same activity list.

// Introduction to Computer Science
import { al20000Sprint01 } from './AL20000Sprint01'
import { al20000Sprint02 } from './AL20000Sprint02'
import { al20000Sprint03 } from './AL20000Sprint03'
import { al20000Sprint04 } from './AL20000Sprint04'
import { al20000Sprint05 } from './AL20000Sprint05'
import { al20000Sprint06 } from './AL20000Sprint06'
import { al20000Sprint07 } from './AL20000Sprint07'
import { al20000Sprint08 } from './AL20000Sprint08'

// Object-Oriented Programming
import { al24500Sprint01 } from './AL24500Sprint01'
import { al24500Sprint02 } from './AL24500Sprint02'
import { al24500Sprint03 } from './AL24500Sprint03'
import { al24500Sprint04 } from './AL24500Sprint04'
import { al24500Sprint05 } from './AL24500Sprint05'
import { al24500Sprint06 } from './AL24500Sprint06'
import { al24500Sprint07 } from './AL24500Sprint07'
import { al24500Sprint08 } from './AL24500Sprint08'

// Web Development
import { al24700Sprint01 } from './AL24700Sprint01'
import { al24700Sprint02 } from './AL24700Sprint02'
import { al24700Sprint03 } from './AL24700Sprint03'
import { al24700Sprint04 } from './AL24700Sprint04'
import { al24700Sprint05 } from './AL24700Sprint05'
import { al24700Sprint06 } from './AL24700Sprint06'
import { al24700Sprint07 } from './AL24700Sprint07'
import { al24700Sprint08 } from './AL24700Sprint08'

// Applied Programming Languages
import { al36000Sprint01 } from './AL36000Sprint01'
import { al36000Sprint02 } from './AL36000Sprint02'
import { al36000Sprint03 } from './AL36000Sprint03'
import { al36000Sprint04 } from './AL36000Sprint04'
import { al36000Sprint05 } from './AL36000Sprint05'
import { al36000Sprint06 } from './AL36000Sprint06'
import { al36000Sprint07 } from './AL36000Sprint07'
import { al36000Sprint08 } from './AL36000Sprint08'

// Software Engineering
import { al44000Sprint01 } from './AL44000Sprint01'
import { al44000Sprint02 } from './AL44000Sprint02'
import { al44000Sprint03 } from './AL44000Sprint03'
import { al44000Sprint04 } from './AL44000Sprint04'
import { al44000Sprint05 } from './AL44000Sprint05'
import { al44000Sprint06 } from './AL44000Sprint06'
import { al44000Sprint07 } from './AL44000Sprint07'
import { al44000Sprint08 } from './AL44000Sprint08'

// Application Frameworks
import { al44500Sprint01 } from './AL44500Sprint01'
import { al44500Sprint02 } from './AL44500Sprint02'
import { al44500Sprint03 } from './AL44500Sprint03'
import { al44500Sprint04 } from './AL44500Sprint04'
import { al44500Sprint05 } from './AL44500Sprint05'
import { al44500Sprint06 } from './AL44500Sprint06'
import { al44500Sprint07 } from './AL44500Sprint07'
import { al44500Sprint08 } from './AL44500Sprint08'

// Software Systems Capstone
import { al49200Sprint01 } from './AL49200Sprint01'
import { al49200Sprint02 } from './AL49200Sprint02'
import { al49200Sprint03 } from './AL49200Sprint03'
import { al49200Sprint04 } from './AL49200Sprint04'
import { al49200Sprint05 } from './AL49200Sprint05'
import { al49200Sprint06 } from './AL49200Sprint06'
import { al49200Sprint07 } from './AL49200Sprint07'
import { al49200Sprint08 } from './AL49200Sprint08'

export const cpsc_20000_activity_lists = [
	(classSectionID, sprint) => al20000Sprint01(classSectionID, sprint),
	(classSectionID, sprint) => al20000Sprint02(classSectionID, sprint),
	(classSectionID, sprint) => al20000Sprint03(classSectionID, sprint),
	(classSectionID, sprint) => al20000Sprint04(classSectionID, sprint),
	(classSectionID, sprint) => al20000Sprint05(classSectionID, sprint),
	(classSectionID, sprint) => al20000Sprint06(classSectionID, sprint),
	(classSectionID, sprint) => al20000Sprint07(classSectionID, sprint),
	(classSectionID, sprint) => al20000Sprint08(classSectionID, sprint) ]

export const cpsc_24500_activity_lists = [
	(classSectionID, sprint) => al24500Sprint01(classSectionID, sprint),
	(classSectionID, sprint) => al24500Sprint02(classSectionID, sprint),
	(classSectionID, sprint) => al24500Sprint03(classSectionID, sprint),
	(classSectionID, sprint) => al24500Sprint04(classSectionID, sprint),
	(classSectionID, sprint) => al24500Sprint05(classSectionID, sprint),
	(classSectionID, sprint) => al24500Sprint06(classSectionID, sprint),
	(classSectionID, sprint) => al24500Sprint07(classSectionID, sprint),
	(classSectionID, sprint) => al24500Sprint08(classSectionID, sprint) ]

export const cpsc_24700_activity_lists = [
	(classSectionID, sprint) => al24700Sprint01(classSectionID, sprint),
	(classSectionID, sprint) => al24700Sprint02(classSectionID, sprint),
	(classSectionID, sprint) => al24700Sprint03(classSectionID, sprint),
	(classSectionID, sprint) => al24700Sprint04(classSectionID, sprint),
	(classSectionID, sprint) => al24700Sprint05(classSectionID, sprint),
	(classSectionID, sprint) => al24700Sprint06(classSectionID, sprint),
	(classSectionID, sprint) => al24700Sprint07(classSectionID, sprint),
	(classSectionID, sprint) => al24700Sprint08(classSectionID, sprint) ]

export const cpsc_36000_activity_lists = [
	(classSectionID, sprint) => al36000Sprint01(classSectionID, sprint),
	(classSectionID, sprint) => al36000Sprint02(classSectionID, sprint),
	(classSectionID, sprint) => al36000Sprint03(classSectionID, sprint),
	(classSectionID, sprint) => al36000Sprint04(classSectionID, sprint),
	(classSectionID, sprint) => al36000Sprint05(classSectionID, sprint),
	(classSectionID, sprint) => al36000Sprint06(classSectionID, sprint),
	(classSectionID, sprint) => al36000Sprint07(classSectionID, sprint),
	(classSectionID, sprint) => al36000Sprint08(classSectionID, sprint) ]

export const cpsc_44000_activity_lists = [
	(classSectionID, sprint) => al44000Sprint01(classSectionID, sprint),
	(classSectionID, sprint) => al44000Sprint02(classSectionID, sprint),
	(classSectionID, sprint) => al44000Sprint03(classSectionID, sprint),
	(classSectionID, sprint) => al44000Sprint04(classSectionID, sprint),
	(classSectionID, sprint) => al44000Sprint05(classSectionID, sprint),
	(classSectionID, sprint) => al44000Sprint06(classSectionID, sprint),
	(classSectionID, sprint) => al44000Sprint07(classSectionID, sprint),
	(classSectionID, sprint) => al44000Sprint08(classSectionID, sprint) ]

export const cpsc_44500_activity_lists = [
	(classSectionID, sprint) => al44500Sprint01(classSectionID, sprint),
	(classSectionID, sprint) => al44500Sprint02(classSectionID, sprint),
	(classSectionID, sprint) => al44500Sprint03(classSectionID, sprint),
	(classSectionID, sprint) => al44500Sprint04(classSectionID, sprint),
	(classSectionID, sprint) => al44500Sprint05(classSectionID, sprint),
	(classSectionID, sprint) => al44500Sprint06(classSectionID, sprint),
	(classSectionID, sprint) => al44500Sprint07(classSectionID, sprint),
	(classSectionID, sprint) => al44500Sprint08(classSectionID, sprint) ]

export const cpsc_49200_activity_lists = [
	(classSectionID, sprint) => al49200Sprint01(classSectionID, sprint),
	(classSectionID, sprint) => al49200Sprint02(classSectionID, sprint),
	(classSectionID, sprint) => al49200Sprint03(classSectionID, sprint),
	(classSectionID, sprint) => al49200Sprint04(classSectionID, sprint),
	(classSectionID, sprint) => al49200Sprint05(classSectionID, sprint),
	(classSectionID, sprint) => al49200Sprint06(classSectionID, sprint),
	(classSectionID, sprint) => al49200Sprint07(classSectionID, sprint),
	(classSectionID, sprint) => al49200Sprint08(classSectionID, sprint) ]

export const ActivityList = (classSectionID, sprint) => {
	function getClassNumberFromClassSectionID(classSectionID) {
		// classSectionID is in the format of “sp24-cpsc-20000-002” where the class number is the third part.
		const parts = classSectionID.split('-');
		return Number(parts[2])
	}

	const classNumber = getClassNumberFromClassSectionID(classSectionID)
	switch(classNumber) {
		case 20000: return cpsc_20000_activity_lists[sprint-1](classSectionID, sprint)
		case 24500: return cpsc_24500_activity_lists[sprint-1](classSectionID, sprint)
		case 24700: return cpsc_24700_activity_lists[sprint-1](classSectionID, sprint)
		case 36000: return cpsc_36000_activity_lists[sprint-1](classSectionID, sprint)
		case 44000: return cpsc_44000_activity_lists[sprint-1](classSectionID, sprint)
		case 44500: return cpsc_44500_activity_lists[sprint-1](classSectionID, sprint)
		case 49200: return cpsc_49200_activity_lists[sprint-1](classSectionID, sprint)
		default: return null
	}
}