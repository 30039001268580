import React from 'react'
import { NavLink } from 'react-router-dom'

import { learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing } from './AL00000Sprint00'

import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { externalLink } from '../DataAndAPIs/Links';
import { goWebServerLink } from '../Activities/GoWebServer'

export const al36000Sprint02 = () => {
	const sprint = 2
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>
		<p><em>Welcome to sprint 2.</em> We covered a lot of material in sprint 1. We even did some programming by completing
		Matchmaker with Go. Keep your Go compilers out for this sprint. We will be enhancing our knowledge of interfaces and 
		packages while working to understand how Functional Programming Frameworks can be used similarly to how we would use 
		object-oriented Frameworks. </p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Compare and contrast object-oriented programming with functional programming</li>
			<li>Create, modify, compile, and execute Go applications using functional programming techniques</li>
			<li>Utilize moderately complex configuration management techniques to effectively work in small teams</li>
			<li>Utilize structs, interfaces, and packages to implement polymorphic and encapsulated programs</li>
		</ul>

		{list36000Sprint02(sprint)}
		{closing(sprint)}

	</div> )
}

export const list36000Sprint02 = (sprint) => {
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, goWebServerLink())}
			<li>Read “Introducing Go” chapters 7 and 8 on Structs, Interfaces, and Packages</li>
			<li>Within O’Reilly Books perform a searches for Golang and ReactJS</li>
			{initialPost(sprint)}
			<li>Review {externalLink('Functional Programming vs Object-Oriented Programming', 'https://www.educba.com/functional-programming-vs-oop/')}</li>
			<li>Review <NavLink to='/activity-oop/patterns'>Object-Oriented Programming Patterns</NavLink></li>
			<li>Review  <NavLink to='/activity-oop/principles'>Object-Oriented Programming Principles</NavLink></li>

			{standardActivitiesClosing(sprint, goWebServerLink(), '8 hours')}
		</ol>
	</div> )
}

// Certified for FA24 on September 8, 2024
// Todo: Update Lab 2 to reflect that "go build" is required for multiple source file builds. Also, review the other instructions for clarity
//     including when to run the application with command line arguments (never) vs calling and API route (always).