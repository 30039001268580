import React from 'react'
import { internalLink, externalLink } from '../DataAndAPIs/Links'
import photo from './InfoEricPoguePhoto.png'

import { _INSTRUCTOR_ERIC_POGUE } from '../DataAndAPIs/Instructors'

export const InfoEricPogue = () => {
	const personalGitHub = () => { return externalLink('https://github.com/EricJPogue', 'https://github.com/EricJPogue') }
	const personalZoomRoom = () => { return externalLink('Personal Zoom link', 'https://us02web.zoom.us/j/6068812502?pwd=ODI5anpCa2RYNHV1Qlp3a3MyT01pUT09') }
	const universityResources = () => { return internalLink('University Resources', '/getting-help')}
	return ( <div>
		<h4>Your Instructor</h4><br />
		<img src={photo} alt='Eric Pogue' height='200' /><br /><br />
		<h5>{_INSTRUCTOR_ERIC_POGUE.name}</h5>
		{_INSTRUCTOR_ERIC_POGUE.title}<br /><br />

		Short Bio: Professional software development leader turned college instructor.<br /><br />

		Email: <a href='mailto:epogue@lewisu.edu'>epogue@lewisu.edu</a><br />
		Zoom: {personalZoomRoom()}<br />
		GitHub: {personalGitHub()}<br /><br />

		Lewis Office Location: {_INSTRUCTOR_ERIC_POGUE.office}<br />
		Office Hours: {_INSTRUCTOR_ERIC_POGUE.officeHours}<br />
		Scheduling an Appointment: Email me with the meeting basics including a requested time and accept the meeting meeting request that I will email you.<br /><br />

		Desk Phone: {_INSTRUCTOR_ERIC_POGUE.lewisPhone}<br />
		Personal Mobile Phone: {_INSTRUCTOR_ERIC_POGUE.mobilePhone}<br /><br />

		<p>How to Contact Me? Email is the best way to contact me for nearly all questions that can not be asked during class. You can expect a 
		maximum response time of 24 hours weekdays.</p>
		<br />
		<p>Need help with University Resources including the Office of Technology, Tutoring, or the Library? Check out 
		the {universityResources()} page</p>
	</div> )
}