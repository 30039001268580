import { xyz_n_1of6, xyz_n_1of6_lists, xyz_2_2of6 } from './SL00000Sprint00'
import { xyz_5_1of6SprintPlanning } from './SL20000Sprint05'

import { basicSlide } from './SLSprint00'
import { checklistAnnouncementsPreworkAndAgenda } from './SL00000Sprint00'
import { submissionPercentage } from './SLSprint00'
import { orderedListSlide, tPrework } from './SLSprint00'
import { completeDeck } from './SL00000Sprint00'
import { tReviewDemoSchedule } from './SL00000Sprint00'
import { sprintDemosIntro, sprintDemos, demoAssignment } from './SLSprint00'
import { metricsAndBobParsonsQuote } from './SL20000Sprint04'

import { list36000Sprint04 } from '../ActivityLists/AL36000Sprint04'
import { list36000Sprint05 } from '../ActivityLists/AL36000Sprint05'

import { breakoutStandard } from './SLSprint00'
import { tLab } from './SL00000Sprint00'

// Sprint Constants
const sprint = 5
const activityList = () => { return list36000Sprint05(sprint) }
const activityListPrevious = () => { return list36000Sprint04(sprint-1) }
const progress = [0, 4, 6, 7, 8, 8 ]

const registration = 'Spring 2024 registration begins November 11... are you ready?'

const breakoutNetworks = () => { 
	const breakoutName = 'Docker Tutorial'
	const breakoutSlide = () => { 
		return breakoutStandard( 
			`Breakout: ${breakoutName}`, 
			`In this breakout session we will explore ${breakoutName}:`, [
				'Host Operating Systems Selection',
				'Docker Installation Desktop and CLI',
				'Status of “welcome-to-docker” execution',
				'Status of “welcome-to-docker” clone, build, and execution',
				'Infoworld tutorial on Docker',
				'Docker Desktop tutorial on Docker',
				'Five most important Docker terms' ]
		)
	}

	return {
		name: breakoutName,
		slide: breakoutSlide
	}
}

const breakoutCustom = () => { 
	const breakoutName = 'Lab 5, Proposals, and Writing Assignment'
	const breakoutSlide = () => { 
		return breakoutStandard( 
			`Breakout: ${breakoutName}`, 
			`In this breakout session we will explore ${breakoutName}:`, [
				'Lab 5 Changes',
				'Finalize Class Project Proposals',
				'Work on Writing Assignment' ]
		)
	}

	return {
		name: breakoutName,
		slide: breakoutSlide
	}
}

const ptt_5_info = {
	'sprint': sprint, 
	'activityList': activityList,
	'activityListPrevious': activityListPrevious,
	'planningExpectations': xyz_5_1of6SprintPlanning,
	'submissionPercentageData': [
		{ name: 'Discussion', due:8, submitted:8 },
		{ name: 'Quiz', due:8, submitted:8 },
		{ name: 'Lab', due:8, submitted:8 },
		{ name: 'Reflection', due: 8, submitted: 8 }],
	'PAaA': [
		xyz_n_1of6_lists(sprint), // Monday
		{ // Wednesday
			'prework':[
				'Complete through activity 4 prior to next class', '',
				`Be prepared Discussion Board ${sprint}`,
				'Be prepared for Lab & Programming Together' ],
			'agenda':[
				`Discussion Board ${sprint} as a scrum team`,
				'Review Demo Schedule for Next Class',
				'Lab & Programming Together',
				'Prework for Next Class', ],
			'announcements':[ 
				'Registration is coming...' ]
		},
		{ // Friday
			'prework':[
				'Complete through activity 6 prior to next class', '',
				'Be prepared for Lab & Programming Together' ],
			'announcements':[ 
				'TBD' ],
			'agenda':[
				'TBD' ]
		},
		{ // Second Monday
			'prework': [
				`Complete through activity ${progress[4]} prior to next class`, '',
				`Be prepared for ${breakoutNetworks().name} Breakout`,
				'Be prepared for Lab and Programming Together' ],
			'announcements':[ 
				`${registration}`,
				'Have you considered the Lewis University 4+1 program?' ],
			'agenda':[
				'Sprint Progress Polling',
				`Breakout: ${breakoutNetworks().name}`,
				'Prework for Next Class',
				'Lab and Programming Together' ],
			'breakout': breakoutNetworks
		}, 
		{ // Second Wednesday
			'prework': [
				`Complete through activity ${progress[5]} prior to next class`, '',
				'Be prepared for Lab and Programming Together' ],
			'announcements':[ 
				`${registration}`,
				`All sprint ${sprint} assignments due Sunday!` ],
			'agenda':[
				'Sprint Progress Polling',
				'Lab',
				`Quiz ${sprint}` ],
			'breakout': breakoutCustom
		}, 
		{ // Second Friday
			'prework': [
				`Complete through activity ${progress[6]} prior to next class`, '',
				'Be prepared for Lab and Programming Together' ],
			'announcements':[ 
				`${registration}`,
				`All sprint ${sprint} assignments due Sunday!` ],
			'agenda':[
				'Final Activity List & Assignment Review',
				'Prework for Next Class',
				'Lab and Programming Together' ],
			'breakout': null
		}
	]
}

// Sprint 5 session 1 of 6: Monday
export const ptt_5_1of6 = () => { return xyz_n_1of6(ptt_5_info.sprint, ptt_5_info.PAaA[0].prework, ptt_5_info.activityListPrevious, ptt_5_info.PAaA[0].agenda, ptt_5_info.PAaA[1].prework, ptt_5_info.activityList, ptt_5_info.planningExpectations) }

// Sprint 5 session 2 of 6: Wednesday
const ptt_5_2of6 = () => { return xyz_2_2of6(ptt_5_info.sprint, ptt_5_info.activityList, ptt_5_info.PAaA[1], ptt_5_info.PAaA[2]) }

const ptt_5_3of6 = () => { 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_5_info.PAaA[2], ptt_5_info.sprint, ptt_5_info.activityList)
	const metricsSubmissionPercentage = () => { return submissionPercentage(ptt_5_info.submissionPercentageData) }
	// Todo: Add the Scrum process pretty slides back into slide deck for Demos and Retrospectives. 
	const retrospective = () => {
		return orderedListSlide('Class Retrospective',
			'Feedback from Assignments & Reflections', [
			'Yet another sprint with 100% submission rate... Thank you!', 
			'Grading with the exception of Lab 3 is complete and grades are posted',
			'You should be able to see the answers to all quiz questions',
			'Multiple reflection comments about appreciating the time programming together',
			'Several reflection comments about the beauty of starting early and wonderful teammates' ])
	}
	const retrospectiveBreakout = () => {
		return orderedListSlide('Breakout Session for Team Retrospective', 
			'As a scrum team consider:', [
			`How does your team feel about sprint ${sprint-1} now that it is over`,
			`What could be done to make sprint ${sprint-1} or the class overall better or more manageable`,
			'What improvements should we make as a class, team, or individual going forward' ])
	}
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_5_info.PAaA[3].prework, sprint, activityList) }

	return completeDeck(slideDeck, [ tReviewDemoSchedule, sprintDemosIntro, sprintDemos, demoAssignment, metricsAndBobParsonsQuote, 
		metricsSubmissionPercentage, retrospective, retrospectiveBreakout, preworkNext ])
}

// Sprint 5 session 4 of 6: Monday
const ptt_x_4of6 = (ptt_x_info) => { 
	const session = 3 // 0-based index for session 4 of 6 in the info.PAaA array
	const poll = () => { return tPrework('Sprint Progress Polling', ptt_x_info.PAaA[session].prework, ptt_x_info.sprint, ptt_x_info.activityList) }
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_x_info.PAaA[session+1].prework, sprint, activityList) }

	const slides = checklistAnnouncementsPreworkAndAgenda(ptt_x_info.PAaA[session], ptt_x_info.sprint, ptt_x_info.activityList)
	return completeDeck(slides, [ poll, ptt_x_info.PAaA[session].breakout().slide, preworkNext, tLab ])	
}
const ptt_5_4of6 = () => { return ptt_x_4of6(ptt_5_info) }

const ptt_x_5of6 = (ptt_x_info) => { 
	const session = 4 // 0-based index for session 5 of 6 in the info.PAaA array
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_x_info.PAaA[session+1].prework, sprint, activityList) }
	const breakout = () => { return ptt_x_info.PAaA[session].breakout().slide() }

	const slides = checklistAnnouncementsPreworkAndAgenda(ptt_x_info.PAaA[session], ptt_x_info.sprint, ptt_x_info.activityList)
	return completeDeck(slides, [ preworkNext, breakout, tLab ])
}

// Sprint 5 session 5 of 6: Wednesday
const ptt_5_5of6 = () => { return ptt_x_5of6(ptt_5_info) }

// Sprint 5 session 6 of 6: Friday
const ptt_x_6of6 = (ptt_x_info) => { 
	const session = 5 // 0-based index for session 6 of 6 in the info.PAaA array
	const ptt_xplus1_1of6_Prework = [
			`All sprint ${ptt_5_info.sprint} assignments due Sunday!`, '',
			`Be prepared for sprint ${ptt_5_info.sprint+1} planning` ]
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_x_info.PAaA[session], ptt_x_info.sprint, ptt_x_info.activityList)
	const finalReviewActivityListAndAssignments = () => { return basicSlide(
		`Final sprint ${ptt_x_info.sprint} activity list and assignment Q&A`, [`Any sprint ${ptt_x_info.sprint} activity list or assignment questions?`]) }
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_xplus1_1of6_Prework, ptt_x_info.sprint, activityList) }

	return completeDeck(slideDeck, [ finalReviewActivityListAndAssignments, preworkNext, tLab ])
}

export const ptt_5_6of6 = () => { return ptt_x_6of6(ptt_5_info) }

export const ptt_5_slidedeck = [ ptt_5_1of6, ptt_5_2of6, ptt_5_3of6,  ptt_5_4of6, ptt_5_5of6, ptt_5_6of6 ]

