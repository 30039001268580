import { list36000Sprint01 } from '../ActivityLists/AL36000Sprint01'
import { list36000Sprint02 } from '../ActivityLists/AL36000Sprint02'

import { xyz_2_1of6PlanningExpectations } from './SL20000Sprint02'
import { xyz_2_2of6 } from './SL00000Sprint00'

import { xyz_n_1of6_lists, xyz_n_1of6, checklistAnnouncementsPreworkAndAgenda, completeDeck, tReviewDemoSchedule, tLab } from './SL00000Sprint00'
import { basicSlide, orderedListSlide, submissionPercentage, tPrework, sprintDemosIntro, sprintDemos, demoAssignment } from './SLSprint00'

import { breakoutStandard } from './SLSprint00'

import { tQuizExpectations, tRecap, tQuiz } from './SLSprint00'

const sprint = 2
const activityList = () => { return list36000Sprint02(sprint) }
const activityListPrevious = () => { return list36000Sprint01(sprint-1) }

// Sprint 2 session 1 of 6: Monday
export const ptt_2_1of6_PAaA = xyz_n_1of6_lists(sprint)
export const ptt_2_1of6 = () => { return xyz_n_1of6(sprint, ptt_2_1of6_PAaA.prework, activityListPrevious, ptt_2_1of6_PAaA.agenda, ptt_2_2of6_PAaA.prework, activityList, xyz_2_1of6PlanningExpectations ) }

// Session 2 of 6: Wednesday
const ptt_2_2of6_PAaA = {
	'prework': [
		'Complete through activity 6 prior to next class', '',
		'Be prepared for Lab & Programming Together Wednesday',
		'Demos and retrospectives will be Friday' ],
	'announcements':[
		'Reducing procrastination is on the agenda',
		'Does everyone know where to find the class recordings?'],
	'agenda':[
		`Sprint ${sprint} Planning Questions & Answers`,
		`Discussion Board ${sprint} as a scrum team`,
		'Confirm scrum team Demos for Next Class',
		'Prework for Next Class',
		'Lab' ]
}
export const ptt_2_2of6 = () => { return xyz_2_2of6(sprint, activityList, ptt_2_2of6_PAaA, ptt_2_3of6_PAaA) }

// Session 3 of 6: Friday
const ptt_2_3of6_PAaA = {
	'prework': [
		'Complete through activity 9 prior to next class', '',
		'Be prepared to discuss “The Information Layer”' ],
	'announcements':[ 
		'Any announcements?' ],
	'agenda':[
		`Sprint ${sprint-1} Demos`,
		`Sprint ${sprint-1} Retrospective`,
		`Breakout for Sprint ${sprint-1} Retrospective`,
		'Prework for Next Class' ]
}
export const ptt_2_3of6 = () => { 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_2_3of6_PAaA, sprint, activityList)
	const metrics = () => {
		return basicSlide(`Sprint ${sprint-1} Metrics`, [
			'What is Bob Parsons Rule #9?', '',
			`Let’s take a minute and review our Sprint ${sprint-1} Submission Percentage class metric.` ])
	}
	const metricsSubmissionPercentage = () => {
		return submissionPercentage([
			{ name: 'Discussion', due:8, submitted:8 },
			{ name: 'Quiz', due:8, submitted:8 },
			{ name: 'Lab', due:8, submitted:8 },
			{ name: 'Reflection', due: 8, submitted: 8 }])
	}
	const retrospective = () => {
		return orderedListSlide('Class Retrospective',
			'Feedback from Assignments & Reflections', [
			'Exceptional submission percentage in sprint 1',
			'Reward for perfect 100% submission percentage... how would we like to celebrate?',
			'All assignments are graded and posted', 
			'Thank you for your reflection comments',
			'Sorry for the broken links and other Blackboard and Activity List issues',
			'Wonderful comments about teammates and working with them... you are appreciated',
			'A few comments about starting late and cramming at the last minute' ])
	}
	const retrospectiveBreakout = () => {
		return orderedListSlide('Breakout Session for Team Retrospective', 
			'As a scrum team consider:', [
			`How does your team feel about sprint ${sprint-1} now that it is over`,
			`What could be done to make sprint ${sprint-1} or the class overall better or more manageable`,
			'What improvements should we make as a class, team, or individual going forward' ])
	}
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_2_4of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ tReviewDemoSchedule, sprintDemosIntro, sprintDemos, demoAssignment, metrics, 
		metricsSubmissionPercentage, retrospective, retrospectiveBreakout, preworkNext ])
}

const breakoutGoProgrammingStepsName = 'Go Programming Steps'
const breakoutGoProgrammingSteps = () => { 
	return breakoutStandard(
		`Breakout: ${breakoutGoProgrammingStepsName}`, 
		`In this breakout session on ${breakoutGoProgrammingStepsName} your team will discuss:`, [
		'Create and Clone GitHub Repository that includes README.md, .gitignore, and LICENSE', 
		'Create Go Module using “go mod init <app-name>”', 
		'Create main.go with “Hello World” type functionality',
		'Test your application with “go run main.go”',
		'Test your application with “go build”',
		'Execute test version of application with “./<app-name>”',
		'Verify debugging functionality on test version using VSCode',
		'Deploy to local production enviornment' ])
}

const ptt_2_4of6_PAaA = {
	'prework': [
		'Complete through activity 10 prior to next class', '',
		`Be prepared for Object-Oriented Programming vs. Functional Programming breakout` ],
	'announcements':[ 
		'Any announcements or questions?' ],
	'agenda':[ 
		'Sprint Progress Polling',
		`Breakout: ${breakoutGoProgrammingStepsName}`,
		'Prework for Next Class',
		'Lab' ]
}

export const ptt_2_4of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_2_4of6_PAaA, sprint, activityList)
	const poll = () => { return tPrework('Sprint Progress Polling', ptt_2_5of6_PAaA.prework, sprint, activityList) }
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_2_5of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ poll, breakoutGoProgrammingSteps, tLab, preworkNext ])
}

// Session 5 of 6: Wednesday
const ptt_2_5of6_PAaA = {
	'prework': [
		'Complete through activity 11 prior to next class', '',
		`Be prepared for Quiz ${sprint}` ],
	'announcements':[ 
		'Everything is due Sunday' ],
	'agenda':[ 
		'Sprint Progress Polling',
		`Breakout: ${breakoutGoProgrammingStepsName}`,
		`Quiz ${sprint}` ],
}

const breakoutOOPandFunctionalName = 'Object-Oriented Pratterns and Principles vs. Functional Programming Patterns and Principles'
const breakoutOOPandFunctional = () => { 
	return breakoutStandard(
		`Breakout: ${breakoutOOPandFunctionalName}`, 
		`In this breakout session on ${breakoutOOPandFunctionalName} your team will discuss:`, [
		'Review 1 OOP Concept and explain it’s applicability to Functional Programming and DevOps',
		'Descibe 2 OOP Patterns and explain thier applicability to Functional Programming and DevOps', 
		'Descibe 2 OOP Principles and explain thier applicability to Functional Programming and DevOps' ])
}

export const ptt_2_5of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_2_5of6_PAaA, sprint, activityList)
	const poll = () => { return tPrework('Sprint Progress Polling', ptt_2_5of6_PAaA.prework, sprint, activityList) }
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_2_6of6_PAaA.prework, sprint, activityList) }
	const quizExpectations = () => { return tQuizExpectations(sprint)}
	const recap = () => { return tRecap(ptt_2_5of6_PAaA.agenda.slice(1))}
	const quiz = () => { return tQuiz(sprint) }

	return 	completeDeck(slideDeck, [ poll, breakoutOOPandFunctional, preworkNext, recap, quizExpectations, quiz ])
}

// Session 6 of 6: Friday
const ptt_2_6of6_PAaA = {
	'prework': [
		'Complete through activity 14', '',
		'Be prepared for Lab',
		`All sprint ${sprint} assignments are due Sunday!` ],
	'announcements':[
		`All sprint ${sprint} assignments are due Sunday!`],
	'agenda':[
		'Lab']
}
export const ptt_2_6of6 = () => {
	const finalReviewActivityListAndAssignments = () => { return basicSlide(
		`Final sprint ${sprint} activity list and assignment Q&A`, [`Any sprint ${sprint} activity list or assignment questions?`]) }
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ptt_2_6of6_PAaA, sprint, activityList)
	const preworkNext = () => { return tPrework('Prework For Next Class', ptt_3_1of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ finalReviewActivityListAndAssignments, preworkNext, tLab ])
}

// Session 1 of 6: Monday
export const ptt_3_1of6_PAaA = xyz_n_1of6_lists(sprint)