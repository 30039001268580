import React from 'react';
import { getClassTitle } from '../DataAndAPIs/ClassSections'
import { baseLink, videoLink } from '../Activities/Activity'

export const ActivityOOPVideo = (topic) => {
	const link = baseLink()+'object-oriented-programming/'+topic+'.mp4'
	return ( <div>
		<h4>{getClassTitle()}</h4>
		{videoLink('', link)}
	</div> )
}