import { xyz_n_1of6_agenda_list, xyz_n_1of6_prework_list, xyz_n_1of6, tLab } from './SL00000Sprint00'
import { completeDeck, checklistAnnouncementsPreworkAndAgenda } from './SL00000Sprint00'

import { sprintDemosIntro, sprintDemos, demoAssignment, reviewDemoSchedule, tExam, tExamExpectations } from './SLSprint00'
import { tPrework, discussionBreakout, submissionPercentage, retrospectiveBreakout, orderedListSlide, retrospectiveIntroduction, breakoutStandard } from './SLSprint00'

import { substituteSprintPlanning } from './SL20000Sprint04'

import { list44000Sprint03 } from '../ActivityLists/AL44000Sprint03'
import { list44000Sprint04 } from '../ActivityLists/AL44000Sprint04'

import { basicSlide } from './SLSprint00'
import { fallBreak } from './SL20000Sprint04'
import { metricsAndBobParsonsQuote } from './SL20000Sprint04'
import { tReviewDemoSchedule } from './SL00000Sprint00'

//import { se_5_1of6_PAaA } from './SL44000Sprint05'

// Sprint 4 Software Engineering (SE) values.
const sprint = 4
const activityListPrevious = () => { return list44000Sprint03(sprint-1) }
const activityList = () => { return list44000Sprint04(sprint) }

// Session 1 of 6: Monday
// Todo: Review Sprint 4 Session 1 (below) as it was fixed but not reviewed after the session.
export const se_4_1of6_prework_list = xyz_n_1of6_prework_list(sprint)  
const se_4_1of6_agenda_list = xyz_n_1of6_agenda_list(sprint)
export const se_4_1of6 = () => { return xyz_n_1of6(sprint, se_4_1of6_prework_list, activityListPrevious, se_4_1of6_agenda_list, se_4_2of6_lists.prework, activityList, substituteSprintPlanning) }

// Session 2 of 6: Wednesday
const se_4_2of6_lists = {
	'announcements':[ 
		'Wednesday, March 6 will be our midterm Exam your in person attendance is required',
		'Your team’s Sprint 5 Planning will be Monday, March 18th',
		'**Each of the remaining sprint planning sessions (including March 18th) require in-person attendance' ],
	'prework':[
		'Complete through activity 3 prior to next class', '',
		`Be prepared Discussion Board ${sprint}`,
		'Be prepared for Lab & Programming Together' ],
	'agenda':[
		'Review Demo Schedule for Next Class',
		'Prework for Next Class',
		`Discussion Board ${sprint} as a scrum team`,
		'Lab: Complete a draft of your Final Project Proposal' ]
}
export const se_4_2of6 = () => {
	const discussion4Breakout = () => { return discussionBreakout(sprint) } 
	const preworkNext = () => { return tPrework('Prework For Next Class', se_4_4of6_PAaA.prework, sprint, activityList) }

	const slideDeck = checklistAnnouncementsPreworkAndAgenda(se_4_2of6_lists, sprint, activityList)
	return completeDeck(slideDeck, [ reviewDemoSchedule, preworkNext, discussion4Breakout, tLab ])
}

// Session 3 of 6: Friday
export const se_4_3of6 = () => { return [ fallBreak ] }

// Session 4 of 6: Monday
const se_4_4of6_PAaA = {
	'prework':[
		'Complete through activity 8', '',
		`Be prepared for sprint ${sprint-1} demos and retrospectives`,
		'Those scheduled to demo please be a couple of minutes early to class',
		'Be prepared for a breakout session on “Software Testing: Test-Driven Development”' ],
	'announcements':[ 
		'**Wednesday is our midterm Exam your in person attendance is required', '',
		'Registration is coming',
		'Are you interested in an area of concentration?... maybe the 4+1 program? ' ],
	'agenda':[
		'Capstone Product Review and Team Product Assignments',
		`Sprint ${sprint-1} Demos`,
		`Sprint ${sprint-1} Retrospective`,
		`Breakout for Sprint ${sprint-1} Retrospective`,
		'“Software Testing: Test-Driven Development” breakout (as time allows)',
		'Prework for Next Class',
		'Lab' ]
}
export const se_4_4of6 = () => {
	//const slideDeck = checklistAnnouncementsPreworkAndAgenda(se_4_4of6_lists, sprint, activityList)
	const breakout = () => { 
		return breakoutStandard( 
			'Breakout: Software Testing: Test-Driven Development', 
			'In this breakout session your team will discuss Software Testing: Test-Driven Development:', [
			'Test-Driven Development (TDD)', 
			'FIRST, TDD, and Red-Green-Refactor', 
			'Code coverage and defect assumptions',
			'Plan and Document vs. Agile testing practices and product quality responsibility',
			'Quality Assurance Organizations vs. Testing Services Organizations vs. Integrated Team Testing'
		])
	}

	const breakoutCapstoneProductReview = () => { 
		return breakoutStandard( 
			'Breakout: Capstone Product Review and Preferences', 
			'In this breakout session your team will:', [
			'Identify who will be continuing on into Capstone (yes, no, maybe)', 
			'Review Capstone Products',
			'Identify 1st, 2nd, and 3rd preference for Capstone Products'
		])

	}

	const slideDeck = checklistAnnouncementsPreworkAndAgenda(se_4_4of6_PAaA, sprint, activityList)
	const metricsSubmissionPercentage = () => {
		const submissionPercentageData = [
			{ name: 'Discussion', due:14, submitted:14 },
			{ name: 'Quiz', due:14, submitted:14 },
			{ name: 'Lab', due:14, submitted:14 },
			{ name: 'Reflection', due: 14, submitted: 14 }]

		return submissionPercentage(submissionPercentageData)
	}
	// Todo: Add the Scrum process pretty slides back into slide deck for Demos and Retrospectives. 
	const retrospective = () => {
		return orderedListSlide('Class Retrospective',
			'Feedback from Assignments & Reflections', [
			'Perfect submission percentage... very well done! Treats on me Wednesday during midterm exam', 
			'Grading is complete and grades are posted',
			'You should be able to see the answers to all quiz questions',
			'Multiple reflection comments about appreciating the time programming together',
			'Many reflection comments about the beauty of starting early and wonderful teammates' ])
	}
	const retrospectiveInto = () => { return retrospectiveIntroduction(sprint)}
	const rB = () => { return retrospectiveBreakout(sprint) }
	const preworkNext = () => { return tPrework('Prework For Next Class', se_4_5of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ breakoutCapstoneProductReview, tReviewDemoSchedule, sprintDemosIntro, sprintDemos, demoAssignment, metricsAndBobParsonsQuote, 
		metricsSubmissionPercentage, retrospectiveInto, retrospective, rB, breakout, preworkNext ])
}

// Session 5 of 6: Wednesday
const se_4_5of6_PAaA = {
	'prework':[
		'**Be prepared to take you midterm exam in person on Wednesday' ],
	'announcements':[ 
		'Reminder that your team’s sprint 5 planning is required, in-person, and scheduled for Monday, March 13th', '',
		`All sprint ${sprint} assignments due Sunday, March 17th!` ],
	'agenda':[
		'Midterm Exam' ],
}
export const se_4_5of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(se_4_5of6_PAaA, sprint, activityList)
	const preworkNext = () => { return tPrework('Prework For Next Class', se_4_6of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ preworkNext, tExamExpectations, tExam ])
}

// Session 6 of 6: Friday
const se_4_6of6_PAaA = {
	'announcements':[ 
		`All sprint ${sprint} assignments due Sunday, March 17!` ],
	'prework':[
		'Be complete with activity 12 working on activity 13 prior to next class', '',
		'Be prepared for Lab & Programming together' ],
	'agenda':[
		'Final Activity List & Assignment Review',
		'Prework for Next Class',
		'Lab', 
		'Backlog Grooming' ]
}
export const se_4_6of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(se_4_6of6_PAaA, sprint, activityList)
	const finalReviewActivityListAndAssignments = () => { return basicSlide(
		`Final sprint ${sprint} activity list and assignment Q&A`, [`Any sprint ${sprint} activity list or assignment questions?`]) }
	const preworkNext = () => { return tPrework('Prework For Next Class', se_5_1of6_PAaA.prework, sprint, activityList) }
	const breakout = () => {
		return basicSlide('Backlog Grooming', [
			`Start backlog grooming in preparation for sprint ${sprint+1} planning.`, 
			`Who is your scrum master, product owner, and mentor for sprint ${sprint+1}` ])
	}

	return completeDeck(slideDeck, [ finalReviewActivityListAndAssignments,preworkNext, tLab, breakout ])
}

const se_5_1of6_PAaA = {
	'prework':[
		`All sprint ${sprint} assignments due Sunday, March 17!`, '',
		`'**Sprint 5 planning is March 18th your  require in-person attendance'Be prepared for sprint 5 planning` ],
	'announcements':[ 
		`` ],
	'agenda':[
		'' ]
}
