// Overview: index.js focuses on the react App including menus and first level routing of URL routes and parameters.
//     Routing should occur with the route first followed by “parameters.” For example, calling “activity” with the 
//     “web-resume” parameter would take the form of “.../activity/web-resume.”

// React and React Router
import React, { Component, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route, Outlet, useParams, useNavigate } from 'react-router-dom'

// React Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'

import './index.css'
import { ClassList } from './NavigationAndHome/ClassList'
import { ModuleList } from './NavigationAndHome/ModuleList'
import { InfoEricPogue } from './Info/InfoEricPogue'
import { StudyTable } from './Activities/StudyTable'
import { UniversityResources } from './Info/UniversityResources'
import { Credits } from './Info/Credits'
import { Welcome } from './ActivityVideo/Panopto'
import { ActivityList } from './ActivityLists/AL'
import { routeActivity } from './Activities/ActivityRouter'
import { SprintCalendar } from './CalendarAndSchedule/SprintCalendar'
import { SprintClassActivities } from './CalendarAndSchedule/SprintSchedule'
import { getSyllabusByCourseSectionID } from './Syllabus'

import { getClassTitle } from './DataAndAPIs/ClassSections'
import { daleLectureVideo } from './ActivityVideo/Panopto'
import { RenderDeck } from './Slides/SlideDeck'
import { ActivityOOPVideo } from './ActivityVideo/ActivityOOP'
import { ActivityConfigVideo } from './ActivityVideo/ActivityConfig'

const App = () => {
	const Version = () => {
		return ( <div>
			<h5>Lewis.education</h5>
			<h6>Version: 2024.fall.05.003</h6>
		</div> )
	}

	const URLRoot = () => {
		return ( <div>
			<Outlet />
		</div> )
	
	}
	const URLModules = () => {
		const { classSectionID } = useParams()
		sessionStorage.setItem('classSectionID', classSectionID)
		return ModuleList(classSectionID)
	}

	const URLRouteActivityList = () => {
		const { classSectionID, sprint } = useParams()
		sessionStorage.setItem('classSectionID', classSectionID)
		const sprintAsANumber = parseInt(sprint)
		return ActivityList(classSectionID, sprintAsANumber)
	}
	const URLRouteActivity = () => {
		// TestURL: http://localhost:3000/activity/web-resume
		const { activity } = useParams()
		return routeActivity(activity)
	}

	const URLRouteActivityDale = () => {
		const { chapter } = useParams()
		return ( <div>
				<h4>{getClassTitle()}</h4>
				<h5>“Computer Science Illuminated” Chapter {chapter} Lecture</h5>
				{daleLectureVideo(chapter)}
		</div> )
	}

	const URLRouteSlideDeck = () => {
		// TestURL: http://localhost:3000/slide-deck/fa24-cpsc-20000-002/1/1/1
		const { classSectionID, sprint, session, totalSessionsInSprint } = useParams()
		sessionStorage.setItem('classSectionID', classSectionID)

		return RenderDeck(classSectionID, sprint, session, totalSessionsInSprint)
	}

	const URLSprintCalendar = () => {
		const { sprint } = useParams()
		return ( <div>
			<SprintCalendar sprint={sprint} />
		</div> )
	}

	const URLSprintSchedule = () => {
		const { sprint } = useParams()
		return ( <div>
			<SprintClassActivities sprint={sprint} />
		</div> )
	}

	const URLNavigateToActivityList = () => {
		const { sprint } = useParams();
		const navigate = useNavigate();
	  
		useEffect(() => {
		  navigate('/activity-list/' + sessionStorage.getItem('classSectionID') + '/' + sprint);
		}, [navigate, sprint]);
	  
		return null;
	}

	const URLSyllabus = () => { 
		return getSyllabusByCourseSectionID(sessionStorage.getItem('classSectionID'))
	}

	const URLWelcomeWithID = () => {
		// TestURL: http://localhost:3000/welcome-with-id/fa24-cpsc-20000-002
		const { classSectionID } = useParams()
		sessionStorage.setItem('classSectionID', classSectionID)
		return Welcome()
	}

	const URLActivityOOP = () => {
		const { topic } = useParams()
		return ActivityOOPVideo(topic)
	}

	const URLActivityConfig = () => {
		const { topic } = useParams()
		return ActivityConfigVideo(topic)
	}

	const URLPageNoteFound = () => {
		return ( <div>
			<h5>Page not found</h5>
		</div> )
	}

	// The following video is a informative about utilizing the “react-router-dom” library:
	// https://www.youtube.com/watch?v=KkHE2hmqMnI
	return ( <div>
		<div className='NavStyle'>
			<HeaderNavigationBar /></div>
		<div className='MainStyle'>
			<BrowserRouter>
				<Routes>
					<Route path='/root' element={<URLRoot />} />
					<Route path='/' element={<ClassList />} />
					<Route path='/classes' element={<ClassList />} />
					<Route path='/modules/:classSectionID' element={<URLModules />} />
					<Route path='/activity-list/:classSectionID/:sprint' element={<URLRouteActivityList />} />
					<Route path='/activity/study-table' element={<StudyTable />} />
					<Route path='/activity/:activity' element={<URLRouteActivity />} />
					<Route path='/activity-dale/:chapter' element={<URLRouteActivityDale />} />
					<Route path='/activity-oop/:topic' element={<URLActivityOOP />} />
					<Route path='/activity-config/:topic' element={<URLActivityConfig />} />

					<Route path='/calendar/:sprint' element={<URLSprintCalendar />} />
					<Route path='/schedule/:sprint' element={<URLSprintSchedule />} />

					<Route path='/info' element={<InfoEricPogue />} />
					<Route path='/getting-help' element={<UniversityResources />} />
					<Route path='/version' element={<Version />} />
					<Route path='/credits' element={<Credits />} />
					<Route path='/welcome' element={<Welcome />} />
					<Route path='/welcome-with-id/:classSectionID' element={<URLWelcomeWithID />} />
					<Route path='/slide-deck/:classSectionID/:sprint/:session/:totalSessionsInSprint' element={<URLRouteSlideDeck />} />

					<Route path='/sprint/:sprint' element={<URLNavigateToActivityList />} />
					<Route path='/syllabus' element={<URLSyllabus />} />

					<Route path='*' element={<URLPageNoteFound />} />
				</Routes>
		</BrowserRouter></div>
	</div> )
}

class HeaderNavigationBar extends Component {
	render() {
		return ( <div>
			<Navbar bg='light' expand='lg'>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='mr-auto'>
						<Nav.Link href='/classes'>Lewis.education</Nav.Link>
							<NavDropdown title='Course Content' id='basic-nav-dropdown'>
								<NavDropdown.Item href='/welcome'>Welcome Message</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href='/syllabus'>Syllabus</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href='/sprint/1'>Sprint 1</NavDropdown.Item>
								<NavDropdown.Item href='/sprint/2'>Sprint 2</NavDropdown.Item>
								<NavDropdown.Item href='/sprint/3'>Sprint 3</NavDropdown.Item>
								<NavDropdown.Item href='/sprint/4'>Sprint 4</NavDropdown.Item>
								<NavDropdown.Item href='/sprint/5'>Sprint 5</NavDropdown.Item>
								<NavDropdown.Item href='/sprint/6'>Sprint 6</NavDropdown.Item>
								<NavDropdown.Item href='/sprint/7'>Sprint 7</NavDropdown.Item>
								<NavDropdown.Item href='/sprint/8'>Sprint 8</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href='/calendar/0'>Calendar</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title='Help' id='basic-nav-dropdown'>
								<NavDropdown.Item href='/info'>Your Instructor</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href='/activity/study-table'>Study Table Tutoring</NavDropdown.Item>
								<NavDropdown.Item href='/getting-help'>University Resources</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href='/credits'>Credits</NavDropdown.Item>
								<NavDropdown.Divider />
                                <NavDropdown.Item href='/version'>Version</NavDropdown.Item>
							</NavDropdown>
						</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div> )
	}
}

ReactDOM.render(<App />, document.getElementById('root'))