import React from 'react'

import { learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing } from './AL00000Sprint00'

import { getModuleDescription, getFinalExamDateAndTime } from '../DataAndAPIs/ClassSections'
import { oreillyPlaylistICS } from '../DataAndAPIs/Links'
import { finalProjectProposalLink } from '../Activities/FinalProjectProposal'
import { finalProjectLink } from '../Activities/FinalProject'

export const al36000Sprint07 = () => {
	const sprint = 7
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>

		<p>Welcome to sprint 7. Sprint 7 puts you in the driver’s seat. It is time for us to work on our Class Projects. 
		We each have <em>three weeks and 20 hours</em> to deliver something uniquely your own. There is time to build something 
		that makes you proud. The only requirement is that you demonstrate what you have learned in class and demonstrate 
		that you are able to extend that learning to something new.</p>
		<p>You have the	power.</p>

		<h5> 
		<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
		“With great power there must also come great responsibility”<br /></div>
		<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
		-Peter Parker (aka Spider-Man)</div>
		</h5>
		<br />

		<p><i>What will you do with all that power... and responsibility?</i></p>
		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Deliver something uniquely your own that makes you proud</li>
			<li>Demonstrate your understanding of the topics we have covered in this class</li>
			<li>Demonstrate your ability to learn and implement something beyond what we covered in this class</li>
		</ul>

		{list36000Sprint07()}
		{closing(sprint)}
	</div> )
}

export const list36000Sprint07 = () => {
	const sprint = 7
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, finalProjectLink(), oreillyPlaylistICS())}
			<li>Verify that you will be presenting your Class Project on <em>{getFinalExamDateAndTime()}</em></li>
			<li>Review your {finalProjectProposalLink()}</li>
		
			{initialPost(sprint)}
			<li>Class Project, Class Project, and more Class Project</li>
			{standardActivitiesClosing(sprint, finalProjectLink(), '18 hours')}
		</ol>
	</div>)
}

export const expectedProgress = [ 0, 4, 7, 7, 8, 9 ]




