import React from 'react'

import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing } from './AL00000Sprint00'

import { FinalProjectProposal, reviewAndUpdate } from './ProgrammingAssignments'
import { azureStaticWebApps, azureStaticReactWithPortal, continuousIntegration, internalLink } from '../DataAndAPIs/Links'
import { MernaIaCLink } from '../Activities/MERNaIaC'

export const al36000Sprint06 = () => {
	const sprint = 6
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>
		<p>In today's fast-paced software development world, delivering high-quality applications quickly and reliably 
		is crucial. Continuous Integration and Continuous Delivery (CI/CD) and Infrastructure as Code (IaC) are two 
		powerful practices that can help you achieve this goal.</p>

		<p>CI/CD automates the process of integrating code changes, running tests, and deploying applications, ensuring 
		that your software is always in a releasable state. This not only speeds up development but also improves code 
		quality and reduces the risk of bugs.</p>

		<p>Infrastructure as Code (IaC) allows you to manage and provision your computing infrastructure through code, 
		making it easy to replicate environments, track changes, and ensure consistency across development, testing, and 
		production.</p>

		<p>By mastering CI/CD and IaC, you'll gain the skills to streamline your development workflow, enhance 
		collaboration, and deliver robust applications with confidence. Dive into these exciting topics and transform 
		the way you build and deploy software!</p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Understand and implement CI/CD</li>
			<li>Understand and implement IaC with CI/CD</li>
			<li>Utilize CI/CD, IaC, and Azure to automate the deployment of multiple interrelated websites and APIs</li>
			<li>Write about the Declarative and Imperative approachs utilizing IaC</li>
		</ul>
		{list36000Sprint06(sprint)}
		{closing(sprint)}
	</div> )
}

export const list36000Sprint06 = (sprint) => {
	const reactOnAzureProgrammingTogether = () => { return internalLink('React on Azure Programming Together', '/activity-config/react-on-azure')}
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, MernaIaCLink())}
			<li>Read {continuousIntegration()}</li>
			<li>{reviewAndUpdate(FinalProjectProposal)}</li>
			<li>Review {azureStaticWebApps()} and {azureStaticReactWithPortal()} </li>
			{initialPost(sprint)}
			<li>Review {reactOnAzureProgrammingTogether()}</li>
			{standardActivitiesClosing(sprint, MernaIaCLink(), '8 hours')}
		</ol>
	</div> )
}